// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgReimburse = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill="#fff"
			d="M19 5.25H5A1.76 1.76 0 0 0 3.25 7v10A1.76 1.76 0 0 0 5 18.75h14A1.76 1.76 0 0 0 20.75 17V7A1.76 1.76 0 0 0 19 5.25M19.25 7v1.67h-.21a1.5 1.5 0 0 1-.36 0h-.13a2 2 0 0 1-.33-.1h-.12a1.3 1.3 0 0 1-.38-.28 1.58 1.58 0 0 1-.47-1.12q0-.21.06-.41H19a.25.25 0 0 1 .25.24M5 6.75h1.69q.06.2.06.41a1.58 1.58 0 0 1-.47 1.12 1.5 1.5 0 0 1-.38.28h-.13a1.1 1.1 0 0 1-.31.1h-.14a1.5 1.5 0 0 1-.36 0h-.21V7A.25.25 0 0 1 5 6.75M4.75 17v-1.67h.21q.18-.022.36 0h.13q.172.028.33.1h.12q.212.11.38.28a1.58 1.58 0 0 1 .47 1.12q0 .21-.06.41H5a.25.25 0 0 1-.25-.24m3.47.25a3 3 0 0 0 0-.41 3 3 0 0 0-.91-2.18 2.6 2.6 0 0 0-.49-.39l-.17-.11a3 3 0 0 0-.36-.16l-.2-.08a3.3 3.3 0 0 0-.53-.11 1.6 1.6 0 0 0-.31 0h-.5v-3.59h.85q.204-.035.4-.1l.22-.07q.23-.079.44-.2l.16-.09a3.4 3.4 0 0 0 .52-.42 3 3 0 0 0 .91-2.18 3 3 0 0 0 0-.41h7.56q-.015.205 0 .41a3 3 0 0 0 .91 2.18q.236.24.52.42l.16.09q.21.121.44.2l.22.07q.201.06.41.09h.84v3.56h-.5a1.6 1.6 0 0 0-.31 0q-.27.033-.53.11l-.2.08a3 3 0 0 0-.39.18l-.17.11q-.268.165-.49.39a3 3 0 0 0-.91 2.18q-.015.204 0 .41zm10.78 0h-1.69a1.5 1.5 0 0 1-.06-.41 1.58 1.58 0 0 1 .47-1.12q.164-.175.38-.28h.12q.16-.063.33-.1h.13q.18-.023.36 0h.21V17a.25.25 0 0 1-.25.25"
		/>
		<path
			fill="#fff"
			d="M12 8.5a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7m0 5.5a2 2 0 1 1 0-4 2 2 0 0 1 0 4"
		/>
	</svg>
);
export default SvgReimburse;
