// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgHsaExpense = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M21.714 11.241v-8.8H11.279v8.8h-8.8v10.442h8.8v8.8h10.438v-8.8h8.794V11.241zm-1.694 8.741v8.795h-7.044v-8.794h-8.8V12.94h8.8v-8.8h7.044v8.8h8.8v7.043z"
		/>
	</svg>
);
export default SvgHsaExpense;
