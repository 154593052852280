// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgMixedExpense = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 34 33"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="m33.083 32.02-1.265-14.069a.84.84 0 0 0-.841-.77H28.47v-1.076a4.073 4.073 0 1 0-8.144 0v1.078h-2.509a.84.84 0 0 0-.84.77l-1.27 14.129a.845.845 0 0 0 .763.917h15.777999999999999a.847.847 0 0 0 .835-.979m-6.3-15.915v1.078h-4.764v-1.08a2.384 2.384 0 0 1 3.253-2.277 2.38 2.38 0 0 1 1.51 2.183q-.002.046-.003.096zm-5.61 4.845a.847.847 0 0 0 .846-.846v-1.232h4.763v1.232a.846.846 0 0 0 1.69 0v-1.232h1.737l1.117 12.438H17.478l1.116-12.434h1.735v1.232a.846.846 0 0 0 .843.842zM12.923 5.773V.144H5.63v5.629H0v7.292h5.63v5.629h7.292v-5.628h5.629V5.773zm-1.7 5.593v5.629h-3.9v-5.628H1.694v-3.9h5.629V1.843h3.894v5.629h5.63v3.894z"
		/>
	</svg>
);
export default SvgMixedExpense;
