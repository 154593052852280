import * as braze from '@braze/web-sdk';

// console.log('REACT_APP_BRAZE_API_KEY---->', process.env.REACT_APP_BRAZE_API_KEY);
// console.log('REACT_APP_BRAZE_SDK_ENDPOINT---->', process.env.REACT_APP_BRAZE_SDK_ENDPOINT);
// initialize the SDK
braze.initialize(process.env.REACT_APP_BRAZE_API_KEY || '', {
	baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT || '',
	enableLogging: false
});

// optionally show all in-app messages without custom handling
braze.automaticallyShowInAppMessages();

// Be sure to call `openSession` after `automaticallyShowInAppMessages`
braze.openSession();
