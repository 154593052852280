import { configureStore } from '@reduxjs/toolkit';
import accountSummaryReducer from './accountSummarySlice';
import backgroundReducer from './backgroundSlice';

const store = configureStore({
	reducer: {
		summaryData: accountSummaryReducer,
		backgroundData: backgroundReducer
	}
});

export default store;
