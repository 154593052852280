export enum StyledLinkSizes {
	SM = 'SMALL',
	MD = 'MEDIUM',
	LG = 'LARGE',
	XL = 'EXTRA_LARGE'
}
export enum StyledLinkTypes {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	TERTIARY = 'TERTIARY'
}
