export enum BannerTypes {
	INFO = 'INFO',
	NEGATIVE = 'NEGATIVE',
	POSITIVE = 'POSITIVE',
	WARNING = 'WARNING'
}
export enum BannerColor {
	INFO = '#296db2',
	NEGATIVE = '#cc3333',
	POSITIVE = '#22c55e',
	WARNING = '#facc15',
	FILL_DARK = '#111827',
	FILL_LIGHT = '#ffffff'
}
