import React from 'react';
// import classes from './PhoneUpdateSuccess.module.scss';

import ConfirmationImage from '../../../../assets/img/ThumbsUpLight.svg';

import { Button, ButtonTypes } from '../../../../components/common/Button';
import { Incomplete } from '../../../../components/icons';
import { StyledLink, StyledLinkSizes } from '../../../../components/common/Link';
import { cardApi } from '../../../../services/cardApi';
import { StyledLinkTypes } from '../../Link/StyledLink.constants';

export const PhoneUpdateSuccess = ({
	closeModal,
	card,
	showContinue = false,
	phoneVerified = false
}: {
	closeModal: () => void;
	card?: any;
	showContinue?: boolean;
	phoneVerified?: boolean;
}) => {
	const getResetPin = () => {
		cardApi
			.changePin(card?.card_id)
			.then((response: any) => {
				window.open(response.data.data.url, '_blank');
			})
			.catch();
	};
	return (
		<div className="">
			<div className="modal-header">
				<div
					onClick={() => {
						closeModal();
					}}
					className="modal-close-btn-container"
				>
					<Incomplete></Incomplete>
				</div>
				<div className={`text-h3-325 text-center mt-32 text-white`}>Your Card is active </div>

				{phoneVerified && (
					<div className={`text-h4-325 mt-4  text-center text-white`}>
						and mobile phone verified{' '}
					</div>
				)}
			</div>
			<div className="modal-body d-flex align-items-center flex-column">
				<img height={200} width={200} className="mt-24  mb-16" src={ConfirmationImage} />
				{!phoneVerified && (
					<React.Fragment>
						<p className="text-base-325 text-primary">
							Your Zenda card can not be used in mobile wallets until you provide and verify your
							mobile phone number. You can do this from the User Profile screen.
						</p>
						<br />
						<p className="text-base-325 text-primary">
							We recommend using your Zenda card as credit whenever possible. However, it’s always a
							good idea to set your PIN.
						</p>
						<br />
					</React.Fragment>
				)}

				{phoneVerified && (
					<React.Fragment>
						<p className="pb-16 text-base-325 text-primary">
							You can now use your Zenda card and add it to mobile wallets
							<br />
							<br />
							We recommend using your Zenda card as credit whenever possible. However, it’s always a
							good idea to set a PIN.
						</p>
					</React.Fragment>
				)}

				{showContinue && (
					<Button type={ButtonTypes.PRIMARY} onClick={closeModal}>
						CONTINUE
					</Button>
				)}
				{!showContinue && (
					<React.Fragment>
						<Button
							type={ButtonTypes.PRIMARY}
							onClick={() => {
								getResetPin();
								closeModal();
							}}
						>
							SET PIN
						</Button>
						<br />
						<StyledLink
							type={StyledLinkTypes.TERTIARY}
							size={StyledLinkSizes.SM}
							onClick={() => {
								closeModal();
							}}
						>
							Skip this step
						</StyledLink>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
