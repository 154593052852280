import React from 'react';
import classes from './MaintenancePage.module.scss';
import { Header } from '../../components/Header';
import { ReactComponent as MaintenanceImage } from '../../assets/img/error.svg';

export const MaintenancePage = () => {
	return (
		<React.Fragment>
			<Header />
			<div className={classes.content}>
				<div className="my-48">
					<MaintenanceImage width={500}></MaintenanceImage>
				</div>
				<p className="text-h2-325">Website under maintenance</p>
				<p className="text-h4-325 py-12">
					Our website is undergoing scheduled maintenance. Please check back later.
				</p>
			</div>
		</React.Fragment>
	);
};
