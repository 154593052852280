// /* eslint-disable import/named */
import { useState, useEffect, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { plaidApi } from '../services/plaidService';

import type {
	PlaidLinkError,
	PlaidLinkOnExitMetadata,
	PlaidLinkOnSuccess,
	PlaidLinkOnSuccessMetadata,
	PlaidLinkOnEvent
} from 'react-plaid-link';

export const usePlaid = (accountType: string) => {
	const [token, setToken] = useState<string | null>(null);
	const [plaidError, setPlaidError] = useState<any>(null);
	const [networkLoading, setNetworkLoading] = useState<boolean>(false);
	const [networkError, setNetworkError] = useState<any>(null);
	const [networkResponse, setNetworkResponse] = useState<any>(null);
	const [plaidEvent, setPlaidEvent] = useState<any>(null);
	const [plaidResponse, setPlaidResponse] = useState<null | {
		publicToken: string;
		metadata: any;
	}>(null);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const linkToAccount = (data: { account_id: string; public_token: string }) => {
		setNetworkLoading(true);
		plaidApi
			.linkPlaidAccount(accountType, data)
			.then(response => {
				setNetworkResponse(response.data);
				setNetworkLoading(false);
			})
			.catch(error => {
				setNetworkError(error);
				setNetworkLoading(false);
			});
	};
	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		(publicToken, metadata) => {
			setPlaidResponse({ publicToken, metadata });
			const successMetadata: PlaidLinkOnSuccessMetadata & { account_id?: string } = metadata;
			linkToAccount({ public_token: publicToken, account_id: successMetadata.account_id || '' });
		},
		[linkToAccount]
	);

	const onExit = async (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
		if (error != null) {
			setPlaidError({ error, metadata });
		}
	};

	const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
		setPlaidEvent({ eventName, metadata });
	}, []);

	const openPlaid = async () => {
		try {
			setNetworkLoading(true);
			const response = await plaidApi.fetchPlaidToken();
			setNetworkLoading(false);
			setToken(response.data.token);
		} catch (connectionError: any) {
			setNetworkError(connectionError);
			setNetworkLoading(false);
		}
	};
	const { open, ready } = usePlaidLink({
		token,
		onSuccess,
		onEvent,
		onExit
	});

	useEffect(() => {
		if (token) {
			open();
		}
	}, [token, open]);

	return {
		plaidError,
		networkError,
		plaidResponse,
		open,
		ready,
		openPlaid,
		networkResponse,
		plaidEvent,
		networkLoading
	};
};
