// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgPlus = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M22.669 10.668h-9.335V1.333a1.332 1.332 0 1 0-2.665 0v9.335H1.334a1.333 1.333 0 0 0 0 2.665h9.335v9.335a1.332 1.332 0 0 0 2.665 0v-9.335h9.335a1.333 1.333 0 0 0 0-2.665"
		/>
	</svg>
);
export default SvgPlus;
