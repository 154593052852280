export enum ButtonTypes {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	TERTIARY = 'TERTIARY',
	TRANSPARENT = 'TRANSPARENT'
}

export enum ButtonSizes {
	DEFAULT = 'DEFAULT',
	COMPACT = 'COMPACT',
	LARGE = 'LARGE'
}

export enum IconColor {
	PRIMARY = '#fafcfe',
	SECONDARY = '#296db2',
	DISABLED = '#b0ceed',
	ERROR = '#aa232c',
	TERTIARY = '#25282b'
}
