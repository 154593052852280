// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgEverydayExpense = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M16.324 2.348a5.33 5.33 0 0 0-5.322 5.322v1.768h-3.74a.87.87 0 0 0-.873.8L4.642 29.7a.88.88 0 0 0 .795.952H27.14a.877.877 0 0 0 .863-1.018v-.013l-1.738-19.386a.874.874 0 0 0-.874-.8h-3.74V7.667a5.327 5.327 0 0 0-5.326-5.319M12.755 7.67a3.57 3.57 0 0 1 6.864-1.368c.18.433.272.897.272 1.365v1.771h-7.137zm11.824 3.522.013.137L26.167 28.9h-19.7l1.588-17.708h2.94v1.991a.876.876 0 0 0 1.753 0v-1.986h7.137v1.986a.876.876 0 1 0 1.753 0v-1.986z"
		/>
	</svg>
);
export default SvgEverydayExpense;
