// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgComplete = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M22 5.6c0 .41-.14.819-.419 1.131l-11.428 12.8a1.34 1.34 0 0 1-1.01.469c-.366 0-.731-.156-1.01-.469l-5.714-6.4A1.7 1.7 0 0 1 2 12c0-.914.667-1.6 1.429-1.6.365 0 .73.156 1.01.469l4.704 5.271 10.42-11.67c.276-.313.642-.47 1.008-.47C21.335 4 22 4.685 22 5.6"
		/>
	</svg>
);
export default SvgComplete;
