import React from 'react';
import classes from './AppFooter.module.scss';
import { StyledLink } from '../common/Link';
import LogoImg from '../../assets/img/INCLogo.svg';
import { StyledLinkSizes, StyledLinkTypes } from '../common/Link/StyledLink.constants';

export const AppFooter = () => {
	return (
		<div className={classes.footer}>
			<div className={classes.container}>
				<div className={`d-flex mb-24 ${classes['mobile-column']}`}>
					<div>
						<StyledLink href="https://www.incomm.com/benefits/" isOpenInNewTab>
							<img width={150} src={LogoImg} alt="Incomm benefits logo" />
						</StyledLink>
					</div>
					<div className={`d-flex ${classes['mobile-column']}`}>
						<div className={`${classes['footer-link']}`}>
							<StyledLink
								href="https://www.incomm.com/benefits/spending-account-faq/"
								isOpenInNewTab
								type={StyledLinkTypes.TERTIARY}
								size={StyledLinkSizes.SM}
							>
								FAQ
							</StyledLink>
							<StyledLink
								href="mailto:support@incommbenefits.com"
								isOpenInNewTab
								type={StyledLinkTypes.TERTIARY}
								size={StyledLinkSizes.SM}
							>
								CONTACT
							</StyledLink>
							{/* <StyledLink href="https://getzenda.com/careers/" isOpenInNewTab>
						CAREERS
					</StyledLink> */}
							<StyledLink
								href="https://incommbenefits.com/privacy/"
								isOpenInNewTab
								type={StyledLinkTypes.TERTIARY}
								size={StyledLinkSizes.SM}
							>
								PRIVACY
							</StyledLink>
						</div>
						<div className={`${classes.copyright}`}>
							Copyright © 2024 InComm Payments, Inc. All Rights Reserved.{' '}
						</div>
					</div>
				</div>

				<div className={classes['text-content']}>
					*Banking services are provided by Coastal Community Bank, Member FDIC. The Zenda Debit
					Card, owned by InComm Payments, is issued by Coastal Community Bank pursuant to a license
					from Visa U.S.A. Inc. Zenda, owned by InComm Payments, HSA spending accounts are FDIC
					insured up to $250,000 per depositor through Coastal Community Bank, Member FDIC. Privacy
					notice for Coastal Community Bank can be found&nbsp;
					<span className={classes['footer-here-link']}>
						<a href="https://www.coastalbank.com/privacy-notice/">here</a>
					</span>
					.
					<p className="py-8">
						Investment accounts are not bank guaranteed and not FDIC insured and may lose value.
					</p>
				</div>
			</div>
		</div>
	);
};
