import { APIService } from './apiService';
import { IFirstLogin, IPasscode, IPreSignup } from '../interface/signup.interface';

const PRE_SIGNUP_END_POINT = '/v2/user/validate/presignup';
const PASS_CODE_SIGNUP_END_POINT = '/v2/user/validate/presignup/passcode';
const FIRST_LOGIN_END_POINT = '/v2/user/firstlogin';
const GA_TAG_END_POINT = '/v2/user/attributes/ga-tags';

export const signupApi = {
	preSignup(postData: IPreSignup) {
		return APIService.post(PRE_SIGNUP_END_POINT, postData, { isTokenRequired: false });
	},
	passCodeVerify(postData: IPasscode) {
		return APIService.post(PASS_CODE_SIGNUP_END_POINT, postData, { isTokenRequired: false });
	},
	firstLogin(patchData: IFirstLogin) {
		return APIService.patch(FIRST_LOGIN_END_POINT, patchData);
	},
	fetchGaTags() {
		return APIService.get(GA_TAG_END_POINT);
	}
};
