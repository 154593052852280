export type OnFocusType = {
	focused: { value: string; label: string };
	isDisabled: boolean;
};

export const onFocus = ({ focused, isDisabled }: OnFocusType) => {
	const msg = `You are currently focused on option ${focused.label}${
		isDisabled ? ', disabled' : ','
	}`;
	return msg;
};
