// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgEveryday = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			fillRule="evenodd"
			d="M24.896 7.408h3.547a2.666 2.666 0 0 1 2.669 2.664v19.26A2.67 2.67 0 0 1 28.444 32H2.669A2.67 2.67 0 0 1 0 29.331v-19.26a2.666 2.666 0 0 1 2.669-2.663h3.253A9.776 9.776 0 0 1 15.409 0c4.583 0 8.43 3.15 9.487 7.408m-9.487-5.632a8.002 8.002 0 0 0-7.644 10.37h4.185a1 1 0 0 1-.017-.102l-.018-.2h2.003l.036.138a2 2 0 0 0 .058.165h3.164c.022-.08.031-.17.036-.26.018-.628-.38-1.012-1.335-1.289l-1.24-.339c-1.758-.553-2.61-1.495-2.61-2.874a3 3 0 0 1 .79-1.95c.49-.53 1.16-.87 1.878-.95V3.93a.8.8 0 0 1 .062-.312.9.9 0 0 1 .179-.263.8.8 0 0 1 .263-.174.9.9 0 0 1 .317-.063q.16 0 .308.067.148.059.263.179a.83.83 0 0 1 .236.576v.544a2.89 2.89 0 0 1 2.544 2.66l.018.205h-1.977l-.027-.157a1.274 1.274 0 0 0-1.428-1.017c-.838 0-1.45.486-1.45 1.151q.001.826 1.187 1.156l1.236.397c1.901.545 2.83 1.566 2.767 3.017q-.007.129-.027.25h3.887a8 8 0 0 0 .245-3.704 8 8 0 0 0-7.89-6.667M2.669 9.184a.887.887 0 0 0-.888.888v2.075h4.14a9.7 9.7 0 0 1-.272-2.963zm23.704 8.295h2.963v-3.556H1.78V29.33c0 .491.397.889.888.889h25.775c.49 0 .892-.398.892-.889v-2.963h-2.963a4.44 4.44 0 0 1-4.445-4.444 4.44 4.44 0 0 1 4.445-4.445m2.958 7.113V19.26h-2.958a2.666 2.666 0 1 0 0 5.332zm-4.435-12.445h4.44v-2.075a.89.89 0 0 0-.892-.888h-3.276q.019.293.018.593a10 10 0 0 1-.29 2.37"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgEveryday;
