import React from 'react';
import classes from './ZendaAuditInfo.module.scss';
import LogoImg from '../../../assets/img/IHLogo.svg';
import { dateUtil } from '../../../utils/dateUtil';
import { getDecimalVal } from '../../../utils/numberUtil';
import { StyledLink, StyledLinkSizes } from '../../common/Link';
import { StyledLinkTypes } from '../../common/Link/StyledLink.constants';
type ZendaAuditInfoProps = {
	closeModal: () => void;
	transactionData?: any;
};
export const ZendaAuditInfo = ({ closeModal, transactionData }: ZendaAuditInfoProps) => {
	return (
		<div className={classes['audit-info-modal']}>
			<div
				className={`${classes['audit-info-header']} py-12 px-16 text-white  d-flex justify-content-between`}
			>
				<div className="d-flex gap-12 align-items-center">
					<img src={LogoImg} />
					<h6 className="text-base-700">Proof of Eligible Purchase</h6>
				</div>
				<div className="d-flex align-items-center">
					<StyledLink
						size={StyledLinkSizes.SM}
						type={StyledLinkTypes.TERTIARY}
						onClick={closeModal}
					>
						Close
					</StyledLink>
				</div>
			</div>
			<div className="d-flex flex-column text-primary py-24 px-16">
				<div>
					<h5 className={`text-h5-350`}>{transactionData?.expense_details?.merchant}</h5>
					<div className={`text-base-325 py-24`}>
						{dateUtil.getFormattedDate(
							new Date(transactionData.expense_details.transaction_date * 1000),
							'MM/DD/YYYY, HH:MM A'
						)}
					</div>
					<div className={`d-flex justify-content-between`}>
						<div className={`text-base-325`}>Expense for: </div>
						<div className={`text-base-325`}> {transactionData.expense_details.expense_for}</div>
					</div>
				</div>

				<hr className={`${classes.hr} my-24`} />
				<h5 className={`text-h5-350`}>Expense Details</h5>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-325 py-8`}>HSA eligible expenses</div>
					<div className={`text-base-325 py-8`}>
						${getDecimalVal(transactionData?.expense_summary?.eligible_amount || 0)}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-325 py-8`}>Non eligible expenses</div>
					<div className={`text-base-325 py-8`}>
						${getDecimalVal(transactionData?.expense_summary?.ineligible_amount || 0)}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-325 py-8`}>
						Mileage: {transactionData?.travel_expense_details?.mileage}mi @ $
						{getDecimalVal(transactionData?.travel_expense_details?.cost_per_mile)}
					</div>
					<div className={`text-base-325 py-8`}>
						${getDecimalVal(transactionData?.travel_expense_details?.mileage_travel_amount)}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-325 py-8`}>Other travel expenses</div>
					<div className={`text-base-325 py-8`}>
						${getDecimalVal(transactionData?.travel_expense_details?.other_travel_amount)}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-700 py-8`}>Total</div>
					<div className={`text-base-700 py-8`}>
						${getDecimalVal(transactionData?.expense_summary?.total_amount || 0)}
					</div>
				</div>
				<hr className={`${classes.hr} my-24`} />
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-700 py-8`}>Merchant Category Code:</div>
					<div className={`text-base-325 py-8`}>{transactionData?.expense_details?.mcc}</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-700 py-8`}>Network:</div>
					<div className={`text-base-325 py-8`}>{transactionData?.expense_details?.network}</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-700 py-8`}>Network Transaction ID:</div>
					<div className={`text-base-325 py-8`}>
						{transactionData?.expense_details?.network_transaction_id}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-700 py-8`}>Card Acceptor ID:</div>
					<div className={`text-base-325 py-8`}>
						{transactionData?.expense_details?.card_acceptor_id}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-700 py-8`}>Transaction ID:</div>
					<div className={`text-base-325 py-8`}>
						{transactionData?.expense_details?.transaction_id}
					</div>
				</div>
				<div className={`${classes['expense-row']}`}>
					<div className={`text-base-325 py-8`}>
						{transactionData?.expense_details?.merchant_description}
					</div>
				</div>
			</div>
		</div>
	);
};
