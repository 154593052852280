// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgPeople = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#111827`}
			fillRule="evenodd"
			d="M9.481 5a3.889 3.889 0 1 1 0 7.778 3.889 3.889 0 0 1 0-7.778m5.89 3.889a5.889 5.889 0 1 0-11.779 0 5.889 5.889 0 0 0 11.778 0M22.52 9.889a3.889 3.889 0 1 1 0 7.778 3.889 3.889 0 0 1 0-7.778m5.888 3.889a5.889 5.889 0 1 0-11.777 0 5.889 5.889 0 0 0 11.777 0M18.63 22.667a5 5 0 0 0-5-5H5.334a5 5 0 0 0-5 5v5.777a1 1 0 1 0 2 0v-5.777a3 3 0 0 1 3-3h8.296a3 3 0 0 1 3 3v5.777a1 1 0 1 0 2 0zM25.778 24.555a3.89 3.89 0 0 1 3.889 3.89 1 1 0 1 0 2 0 5.89 5.89 0 0 0-5.889-5.89h-3.26a1 1 0 0 0 0 2z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgPeople;
