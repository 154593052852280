import fileTypeChecker from 'file-type-checker';

export const fileUtils = {
	validateFileType: async (file: File, fileTypes = ['jpeg', 'png', 'pdf']) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				if (
					fileTypeChecker.validateFileType(reader.result as ArrayBuffer, fileTypes, {
						excludeSimilarTypes: true
					})
				) {
					resolve(file);
				} else {
					reject('Invalid format');
				}
			};
			reader.readAsArrayBuffer(file);
		});
	},

	validFilesSize: (files: any, maxMb = 10) => {
		for (let i = 0; i < files.length; i = i + 1) {
			if (files[i].size / 1000000 > maxMb) {
				return false;
			}
		}
		return true;
	},

	validateFileName: (files: any) => {
		const reg = new RegExp(/[^a-zA-Z0-9!_.*'()-\s]/g);
		return [...files].every((file: any) => !reg.test(file.name));
	},

	sanitizeFileName: (fileName: string) => {
		// Replace problematic characters with underscores or remove them
		return fileName
			.replace(/[\x00-\x1F<>:"/\\|?*#%&]/g, '_') // Control characters and some special characters
			.replace(/ +/g, '_') // Replace spaces with underscores
			.replace(/^\.+|\.+$/g, '') // Remove leading or trailing dots
			.replace(/\.\./g, '.') // Replace double dots with a single dot
			.replace(/\s+/g, '_') // Replace multiple spaces with a single underscore
			.replace(/[^\x20-\x7E]/g, '') // Remove non-printable ASCII characters
			.trim(); // Trim leading/trailing whitespace
	}
};
