// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgMessage = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M28.8 1.333H3.2a3.22 3.22 0 0 0-3.2 3.2v21.943a3.22 3.22 0 0 0 3.2 3.2h25.6a3.22 3.22 0 0 0 3.2-3.2V4.533a3.22 3.22 0 0 0-3.2-3.2M3.2 4.076h25.6a.457.457 0 0 1 .457.457V7.35L16 13.97 2.743 7.349V4.533a.457.457 0 0 1 .457-.457m25.6 22.857H3.2a.457.457 0 0 1-.457-.457V10.403l12.635 6.327a1.39 1.39 0 0 0 1.244 0l12.635-6.327v16.073a.457.457 0 0 1-.457.457"
		/>
	</svg>
);
export default SvgMessage;
