// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgFaq = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			fillRule="evenodd"
			d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32m0 29.333a13.334 13.334 0 1 1 0-26.668 13.334 13.334 0 0 1 0 26.668M11.911 8.356a5.78 5.78 0 0 0-1.689 4.088 1.333 1.333 0 0 0 2.667 0 3.1 3.1 0 0 1 .907-2.204 3.2 3.2 0 0 1 4.408 0A3.11 3.11 0 0 1 16 15.573a1.35 1.35 0 0 0-1.333 1.334v1.777a1.333 1.333 0 1 0 2.666 0v-.604c1.045-.24 2-.77 2.756-1.529a5.796 5.796 0 0 0 0-8.178 5.94 5.94 0 0 0-8.178-.017m6.311 15.2a2.222 2.222 0 1 1-4.444 0 2.222 2.222 0 0 1 4.444 0"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgFaq;
