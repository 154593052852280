import classes from './TransactionTableV2.module.scss';
import React from 'react';
import {
	Contribution,
	Deposit,
	EverydayExpense,
	HsaExpense,
	InterestPayment,
	MixedExpense,
	Reimbursement,
	Transfer
} from '../../icons';
import { ITransaction } from '../../../interface/common.interface';
import { TransactionAndExpenseDes } from '../../../constants/common';
import IconNavLink from '../IconNavLink';
import { ICON_NAV_LINK_TYPES } from '../IconNavLink/IconNavLink.constants';

type TableProps = {
	sections: Array<{ sectionName: string; data: Array<ITransaction> }>;
	children?: JSX.Element | Array<JSX.Element> | string;
	type?: string;
};

export const TransactionTableV2 = ({ children, sections }: TableProps) => {
	/**
	 *
	 * @param transaction
	 * @returns color of the description based on status
	 */
	const getDesClass = (transaction: ITransaction) => {
		switch (transaction.description.toLowerCase()) {
			case TransactionAndExpenseDes.RECEIPT_RECOMMENDED:
			case TransactionAndExpenseDes.PARTIALLY_REIMBURSED:
			case TransactionAndExpenseDes.AWAITING_REIMBURSEMENT:
			case TransactionAndExpenseDes.HOLD_AWAITING_APPROVAL:
				return classes['text-yellow'];
			case TransactionAndExpenseDes.ELIGIBLE_FOR_REIMBURSEMENT:
				return classes['green-text'];
			case TransactionAndExpenseDes.NEEDS_ADDITIONAL_INFO:
			case TransactionAndExpenseDes.ACTION_NEEDED:
				return classes['blue-text'];
			default:
				return '';
		}
	};

	const getIcons = (transaction: ITransaction) => {
		const otherProps = { 'aria-hidden': true };
		switch (transaction.image) {
			case 'medical':
				return <HsaExpense width={32} height={32} {...otherProps} />;
			case 'deposit':
				return <Deposit width={32} height={32} {...otherProps} />;
			case 'contribution':
				return <Contribution width={32} height={32} {...otherProps} />;
			case 'reimbursement':
				return <Reimbursement width={32} height={32} {...otherProps} />;
			case 'interest-payment':
				return <InterestPayment width={32} height={32} {...otherProps} />;
			case 'mixed':
				return <MixedExpense width={32} height={32} {...otherProps} />;
			case 'non-medical':
				return <EverydayExpense width={32} height={32} {...otherProps} />;
			case 'ach-withdrawal':
			case 'check-withdrawal':
			case 'reimbursement/deposit':
				return <Reimbursement width={32} height={32} {...otherProps} />;
			case 'investment':
				return <Transfer width={32} height={32} {...otherProps} />;
			default:
				return <EverydayExpense width={32} height={32} {...otherProps} />;
		}
	};

	return (
		<React.Fragment>
			<div style={{ overflow: 'auto' }}>
				<div className="d-flex flex-column">
					{sections.map((section, index) => {
						return (
							<div key={`transaction-section-${index}`} className={classes['transaction-section']}>
								<h6 className={classes['group-name']}>{section.sectionName}</h6>
								{section.data.map((t: any, transaction_index: number) => {
									const transactionProps = {
										type: t.show_details ? ICON_NAV_LINK_TYPES.LINK : ICON_NAV_LINK_TYPES.STATIC,
										href: t.expense_id
											? `/apps/expense?id=${t.expense_id}`
											: `/apps/transaction?id=${t.id}`
									};

									return (
										<IconNavLink
											key={`transaction-${t.id}`}
											type={transactionProps.type}
											isFirst={transaction_index === 0}
											isLast={section.data.length - 1 === transaction_index}
											href={transactionProps.href}
											leftIcon={getIcons(t)}
											title={t.activity}
											mainSubtitle={
												<p className={`d-flex text-secondary align-items-center ${getDesClass(t)}`}>
													{t.description}
												</p>
											}
											additionalInfo={t.amount}
											additionalSubInfo={t.date}
											hasRightArrow={t.show_details}
										/>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
			{children}
		</React.Fragment>
	);
};
