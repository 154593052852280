import { APIService } from './apiService';

const INVESTMENT_END_POINT = '/v2/user/investment/account/session-id';
const INVESTMENT_ACCOUNT_END_POINT = '/v2/user/investment/account/status';
const INVESTMENT_ACCOUNT_BALANCE_END_POINT = '/v2/user/investment/account/details';
const API_END_ACCOUNT_CREATE = '/v2/user/investment/account/create';
const API_END_TRANSFER_FUND = '/v2/user/investment/account/fund';
const API_END_LOAD_MONEY = '/v2/user/transaction/ext/load-money';

interface ILOADMONEY {
	ext_account_id: string;
	txn_type: 'C' | 'D';
	reload_type: 'OneTime' | 'Recurring';
	amount: number;
}

export const investmentApi = {
	fetchSessionId() {
		return APIService.get(INVESTMENT_END_POINT);
	},
	accountStatus() {
		return APIService.get(INVESTMENT_ACCOUNT_END_POINT);
	},
	fetchBalance() {
		return APIService.get(INVESTMENT_ACCOUNT_BALANCE_END_POINT);
	},
	openAccount(data: any) {
		return APIService.post(API_END_ACCOUNT_CREATE, data);
	},
	transferFund(data: any) {
		return APIService.post(API_END_TRANSFER_FUND, data);
	},
	loadMoney(data: ILOADMONEY) {
		return APIService.post(API_END_LOAD_MONEY, data);
	}
};
