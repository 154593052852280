/* eslint-disable max-lines */
import React, { useState } from 'react';
import classes from './DeleteExpense.module.scss';

import { Button, ButtonTypes } from '../../common/Button';
import { Loader } from '../../../components/common/Loader';
import Modal from 'react-modal';
import { Incomplete } from '../../icons';

import { expenseApi } from '../../../services/expenseService';

type StepProps = {
	isOpen: boolean;
	expenseId: string;
	onSuccess: () => void;
	onCancel: () => void;
};

export const DeleteExpense = ({ isOpen, expenseId, onSuccess, onCancel }: StepProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '700px',
			transform: 'translate(-50%, -50%)',
			padding: '56px 24px',
			backgroundColor: '#001b47',
			borderColor: '#001b47'
		}
	};

	const deleteExpense = () => {
		setIsLoading(true);
		expenseApi
			.deleteExpense(expenseId)
			.then(() => {
				setIsLoading(false);
				onSuccess();
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			style={customStyles}
			contentLabel="delete Expense"
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="d-flex flex-column px-64">
				{isLoading ? (
					<Loader />
				) : (
					<React.Fragment>
						<div
							onClick={() => {
								onCancel();
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div
							className={`d-flex justify-content-center ${classes['text-orange']} ${classes['text-center']} text-h3-325`}
						>
							Are you sure you want to delete this expense?
						</div>
						<div className={`d-flex justify-content-center gap-20 pt-32`}>
							<Button type={ButtonTypes.TERTIARY} onClick={deleteExpense}>
								YES
							</Button>
							<Button
								onClick={() => {
									onCancel();
								}}
							>
								NO
							</Button>
						</div>
					</React.Fragment>
				)}
			</div>
		</Modal>
	);
};
