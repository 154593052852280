// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgTransfer = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		viewBox="0 0 30 30"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<defs>
			<clipPath id="transfer_svg__a">
				<path fill="none" d="M0 0h30v27.307H0z" data-name="Rectangle 1367" />
			</clipPath>
		</defs>
		<path fill="rgba(239,243,250,0)" d="M0 0h30v30H0z" data-name="Rectangle 423" />
		<g data-name="Group 16136">
			<g clipPath="url(#transfer_svg__a)" data-name="Group 16134" transform="translate(0 1.346)">
				<path
					fill={props.color || `#25282B`}
					d="M15.7 21.505a7.907 7.907 0 1 0-1.4 0 11 11 0 0 0 1.4 0m-.708-1.4a6.459 6.459 0 1 1 6.458-6.459v.1a6.46 6.46 0 0 1-6.455 6.359Z"
					data-name="Path 28749"
				/>
				<path
					fill={props.color || `#25282B`}
					d="m15.688 13.046-1-.318q-.952-.266-.952-.928c0-.535.49-.924 1.165-.924a1.03 1.03 0 0 1 1.149.815l.02.125h1.585l-.013-.163a2.33 2.33 0 0 0-2.043-2.135v-.439a.654.654 0 0 0-1.308-.011v.449a2.4 2.4 0 0 0-2.141 2.327c0 1.108.684 1.863 2.1 2.31l1 .272c.767.222 1.087.531 1.072 1.034-.014.587-.528.967-1.31.967a1.225 1.225 0 0 1-1.305-.89l-.029-.113h-1.616l.015.164a2.445 2.445 0 0 0 2.213 2.161v.453a.654.654 0 0 0 1.308.011v-.445a2.47 2.47 0 0 0 2.308-2.3c.053-1.167-.693-1.985-2.218-2.423"
					data-name="Path 28750"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M6.247 14.377a8.4 8.4 0 0 1 .084-2.089H5.074v-1.591L0 13.625l5.084 2.937v-1.6h1.24a8 8 0 0 1-.078-.585"
					data-name="Path 28751"
				/>
				<path
					fill={props.color || `#25282B`}
					d="m30 13.633-5.084-2.936v1.6h-1.254c.031.2.065.392.082.592a8.4 8.4 0 0 1-.082 2.085h1.265v1.589Z"
					data-name="Path 28752"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M15.663 2.201v1.388a.66.66 0 0 1-.652.707.67.67 0 0 1-.675-.691q-.011-1.412 0-2.823a.66.66 0 0 1 .674-.669.65.65 0 0 1 .652.675c.005.47 0 .941 0 1.411"
					data-name="Path 28753"
				/>
				<path
					fill="none"
					stroke={props.color || `#25282B`}
					strokeWidth={0.25}
					d="M15.663 2.201v1.388a.66.66 0 0 1-.652.707.67.67 0 0 1-.675-.691q-.011-1.412 0-2.823a.66.66 0 0 1 .674-.669.65.65 0 0 1 .652.675c.006.472.001.943.001 1.413Z"
					data-name="Path 28754"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M22.001 7.222a.79.79 0 0 1-.653-.454.63.63 0 0 1 .144-.728q.9-.91 1.81-1.813c.073-.073.145-.149.221-.218a.661.661 0 0 1 .946.923c-.305.327-.629.635-.945.952-.35.35-.691.708-1.053 1.045a3 3 0 0 1-.47.293"
					data-name="Path 28755"
				/>
				<path
					fill="none"
					stroke={props.color || `#25282B`}
					strokeWidth={0.25}
					d="M22.001 7.222a.79.79 0 0 1-.653-.454.63.63 0 0 1 .144-.728q.9-.91 1.81-1.813c.073-.073.145-.149.221-.218a.661.661 0 0 1 .946.923c-.305.327-.629.635-.945.952-.35.35-.691.708-1.053 1.045a3 3 0 0 1-.47.293Z"
					data-name="Path 28756"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M6.121 3.819a.6.6 0 0 1 .375.208q1.012.986 2 1.993a.66.66 0 0 1 .027.946.65.65 0 0 1-.96-.005 209 209 0 0 1-2-1.995.63.63 0 0 1-.144-.748.74.74 0 0 1 .7-.4"
					data-name="Path 28757"
				/>
				<path
					fill="none"
					stroke={props.color || `#25282B`}
					strokeWidth={0.25}
					d="M6.121 3.819a.6.6 0 0 1 .375.208q1.012.986 2 1.993a.66.66 0 0 1 .027.946.65.65 0 0 1-.96-.005 209 209 0 0 1-2-1.995.63.63 0 0 1-.144-.748.74.74 0 0 1 .702-.399Z"
					data-name="Path 28758"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M14.337 25.108V23.72a.66.66 0 0 1 .652-.707.67.67 0 0 1 .675.691q.011 1.412 0 2.823a.66.66 0 0 1-.67.668.65.65 0 0 1-.656-.677c-.005-.47 0-.941 0-1.411"
					data-name="Path 28759"
				/>
				<path
					fill="none"
					stroke={props.color || `#25282B`}
					strokeWidth={0.25}
					d="M14.337 25.108V23.72a.66.66 0 0 1 .652-.707.67.67 0 0 1 .675.691q.011 1.412 0 2.823a.66.66 0 0 1-.67.668.65.65 0 0 1-.656-.677c-.006-.471-.001-.942-.001-1.41Z"
					data-name="Path 28760"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M7.998 20.086a.79.79 0 0 1 .653.454.63.63 0 0 1-.144.728q-.9.91-1.81 1.813c-.073.073-.145.149-.221.218a.661.661 0 0 1-.946-.923c.305-.327.629-.635.945-.952.35-.35.691-.708 1.053-1.045a3 3 0 0 1 .47-.293"
					data-name="Path 28761"
				/>
				<path
					fill="none"
					stroke={props.color || `#25282B`}
					strokeWidth={0.25}
					d="M7.998 20.086a.79.79 0 0 1 .653.454.63.63 0 0 1-.144.728q-.9.91-1.81 1.813c-.073.073-.145.149-.221.218a.661.661 0 0 1-.946-.923c.305-.327.629-.635.945-.952.35-.35.691-.708 1.053-1.045a3 3 0 0 1 .47-.293Z"
					data-name="Path 28762"
				/>
				<path
					fill={props.color || `#25282B`}
					d="M23.879 23.488a1.4 1.4 0 0 1-.375-.208c-.676-.656-1.34-1.323-2-1.993a.66.66 0 0 1-.027-.946.647.647 0 0 1 .96.006q1.007.99 2 1.994a.63.63 0 0 1 .144.748.7.7 0 0 1-.7.4"
					data-name="Path 28763"
				/>
				<path
					fill="none"
					stroke={props.color || `#25282B`}
					strokeWidth={0.25}
					d="M23.879 23.488a1.4 1.4 0 0 1-.375-.208c-.676-.656-1.34-1.323-2-1.993a.66.66 0 0 1-.027-.946.647.647 0 0 1 .96.006q1.007.99 2 1.994a.63.63 0 0 1 .144.748.7.7 0 0 1-.702.399Z"
					data-name="Path 28764"
				/>
			</g>
		</g>
	</svg>
);
export default SvgTransfer;
