import { APIService } from './apiService';

const PLAID_TOKEN_END_POINT = '/v2/user/accounts/ext/plaid/link/token/bank-account';
const PLAID_ACCOUNT_END_POINT = '/v2/user/accounts/ext/';

export const plaidApi = {
	fetchPlaidToken() {
		return APIService.get(PLAID_TOKEN_END_POINT, { platform: 'web' });
	},
	linkPlaidAccount(accountType: string, data: { account_id?: string; public_token?: string }) {
		return APIService.post(`${PLAID_ACCOUNT_END_POINT}${accountType}`, data);
	},
	getBankAccountDetails() {
		return APIService.get(`${PLAID_ACCOUNT_END_POINT}bank-account`);
	},
	unlinkPlaidAccount(accountType: string) {
		return APIService.delete(`${PLAID_ACCOUNT_END_POINT}${accountType}`);
	}
};
