import React, { useId } from 'react';
import classes from './IconNavLink.module.scss';
import { Link } from 'react-router-dom';
import { ChevronClose } from '../../icons';
import {
	TButtonProps,
	TIconsNavLinkContentProps,
	TIconsNavLinkProps,
	TLinkProps
} from './IconNavLink.types';
import { ICON_NAV_LINK_TYPES } from './IconNavLink.constants';
import { getClassNames } from '../../../utils/Css';
import { Button, ButtonSizes, ButtonTypes } from '../Button';

const IconNavLinkContent: React.FC<TIconsNavLinkContentProps> = ({
	type,
	leftIcon,
	title,
	mainSubtitle,
	additionalInfo,
	additionalSubInfo,
	hasRightArrow = true,
	rightComponent,
	arrowColor = 'black',
	rightArrowLabel = 'Go to the details'
}) => {
	const rightIconId = useId();
	return (
		<div className={`d-flex align-items-center ${classes['btn-content']}`}>
			{/* Left Part of the button/link */}
			<div className={`${classes['btn-content-left']} d-flex align-items-center `}>{leftIcon}</div>
			{/* Center Part of the button/link */}
			<div className={`d-flex jusitfy-content-end ${classes['btn-content-center']} text-base-700`}>
				<div className={classes['center-left']}>
					<div>{title}</div>
					{mainSubtitle && <div className="text-sm-325 mt-2">{mainSubtitle}</div>}
				</div>
				{additionalInfo && (
					<div className={classes['center-right']}>
						<div>{additionalInfo}</div>
						{additionalSubInfo && (
							<div className={`text-sm-325 mt-2 text-secondary ${classes['additional-subinfo']}`}>
								{additionalSubInfo}
							</div>
						)}
					</div>
				)}
			</div>
			{/* Right Part of the button/link */}
			{type === ICON_NAV_LINK_TYPES.STATIC ? (
				<div className={classes['static-temp-div']}>{rightComponent}</div>
			) : (
				''
			)}
			{hasRightArrow && (
				<div className={`${classes['btn-content-right']} d-flex align-items-center `}>
					<ChevronClose
						color={arrowColor}
						titleId={`right-icon-${rightIconId}`}
						title={rightArrowLabel}
					/>
				</div>
			)}
		</div>
	);
};

export const IconNavLink: React.FC<TIconsNavLinkProps> = ({
	isFirst = false,
	isLast = false,
	type = ICON_NAV_LINK_TYPES.LINK,
	isDisabled = false,
	wrapperClasses,
	...rest
}) => {
	const classNames = getClassNames(
		isFirst && classes['is-first-button'],
		isLast && classes['is-last-button'],
		classes['icon-link-wrapper'],
		type === ICON_NAV_LINK_TYPES.BUTTON && classes['icon-btn-wrapper'],
		type === ICON_NAV_LINK_TYPES.STATIC && classes['icon-div-wrapper'],
		wrapperClasses
	);

	const renderContent = () => <IconNavLinkContent {...rest} type={type} />;

	switch (type) {
		case ICON_NAV_LINK_TYPES.BUTTON:
			return (
				<Button
					type={ButtonTypes.TRANSPARENT}
					size={ButtonSizes.DEFAULT}
					otherClasses={classNames}
					onClick={(rest as TButtonProps).handleClick}
				>
					{renderContent()}
				</Button>
			);
		case ICON_NAV_LINK_TYPES.STATIC:
			return <div className={classNames}>{renderContent()}</div>;
		default:
			return (
				<Link to={isDisabled ? '' : (rest as TLinkProps).href} className={classNames}>
					{renderContent()}
				</Link>
			);
	}
};
