import { AccountType } from '../constants/common';

type AccountSummary = {
	xfsa_summary: Array<Record<string, unknown>>;
	hsa_summary: Array<Record<string, unknown>>;
	external_account_linked: boolean;
	expense_count: number;
};
export const isAccountClosed = (summary: AccountSummary): boolean => {
	return (
		summary.hsa_summary.length <= 2 &&
		summary.xfsa_summary.length == 0 &&
		!!summary.hsa_summary.find(
			account => account.account_status == 'closed' && account.type == AccountType.HSA
		)
	);
};
