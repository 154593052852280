import { APIService } from './apiService';

const EXPENSE_TYPE_END_POINT = '/v2/user/expense/types';
const DRAFT_END_POINT = '/v2/user/expense/draft';
const EXPENSE_EVALUATE_END_POINT = '/v2/user/expense/evaluate';
const EXPENSE_END_POINT = '/v2/user/expense';
const EXPENSE_SUMMARY_END_POINT = '/v2/user/expenses/groups';
const API_END_TRANSACTION = '/v2/user/transaction';
const POC_UPLOAD_END_POINT = '/v2/user/file/upload';
const SCANNED_FILE_END_POINT = '/v2/user';

export const expenseApi = {
	getExpenseType() {
		return APIService.get(EXPENSE_TYPE_END_POINT);
	},

	createDraft(data: any) {
		return APIService.post(DRAFT_END_POINT, data);
	},

	updateExpenseDraft(expenseDraftId: string, data: any) {
		return APIService.patch(`${DRAFT_END_POINT}/${expenseDraftId}`, data);
	},

	getEvaluate(data: any) {
		return APIService.post(EXPENSE_EVALUATE_END_POINT, data);
	},

	getDocumentUploadUrl(expenseDraftId: string, data: any) {
		return APIService.post(`${DRAFT_END_POINT}/${expenseDraftId}/documents`, data);
	},

	deleteExpenseDocuments(expenseDraftId: string, data: any) {
		return APIService.delete(`${DRAFT_END_POINT}/${expenseDraftId}/documents`, data);
	},

	deleteExpenseAttachment(expenseId: string, data: any) {
		return APIService.delete(`${EXPENSE_END_POINT}/${expenseId}/attachments`, data);
	},

	createExpense(data: any) {
		return APIService.post(EXPENSE_END_POINT, data);
	},

	getExpenseGroupSummary() {
		return APIService.get(EXPENSE_SUMMARY_END_POINT);
	},

	getExpenseList(expenseGroup: string, limit: string | number, offset: string | number) {
		return APIService.get(`${EXPENSE_END_POINT}s/${expenseGroup}?limit=${limit}&offset=${offset}`);
	},

	getExpense(expenseId: string) {
		return APIService.get(`${EXPENSE_END_POINT}/${expenseId}`);
	},

	editAttachment(expenseId: string, attachments: any) {
		return APIService.post(`${EXPENSE_END_POINT}/${expenseId}/upload`, attachments);
	},

	reSubmit(expenseId: string) {
		return APIService.post(`${EXPENSE_END_POINT}/${expenseId}/claim/re-submit`, {
			expense_id: expenseId
		});
	},
	reImburseExpense(id: number | string, data: any) {
		return APIService.post(`${EXPENSE_END_POINT}/${id}`, data);
	},

	fixAssert(id: number | string, data: any) {
		return APIService.post(`${EXPENSE_END_POINT}/${id}/fix-assert`, data);
	},

	deleteExpense(expenseId: string) {
		return APIService.delete(`${EXPENSE_END_POINT}/${expenseId}`);
	},

	cancelExpense(expenseId: string) {
		return APIService.delete(`${EXPENSE_END_POINT}/${expenseId}/cancel`);
	},

	getTransactionsDetails(id: number | string) {
		return APIService.get(`${API_END_TRANSACTION}/${id}`);
	},

	fileUpload(data: any) {
		return APIService.post(POC_UPLOAD_END_POINT, data);
	},

	getScannedFile(fileId: string) {
		return APIService.get(`${SCANNED_FILE_END_POINT}/${fileId}/status`);
	}
};
