// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgClear = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			fillRule="evenodd"
			d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12m1.257-12 4.4 4.4a.888.888 0 1 1-1.256 1.257l-4.4-4.4-4.4 4.4a.888.888 0 1 1-1.257-1.256l4.4-4.4-4.4-4.401A.888.888 0 0 1 7.6 6.344l4.4 4.4 4.401-4.4A.888.888 0 1 1 17.657 7.6z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgClear;
