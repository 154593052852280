import { localStorageUtil } from '../utils/localStorageUtil';
import type { InternalAxiosRequestConfig, AxiosResponse } from 'axios';

import axios from 'axios';
// import { APIService } from '../services/apiService';
import UserPool from '../utils/UserPool';
import * as Sentry from '@sentry/react';

export const customAxios = axios.create({
	baseURL: process.env.REACT_APP_API_DOMAIN || '',
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json'
	}
});

// Request interceptor for API calls
customAxios.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const { isTokenRequired, ...restParams } = config?.params;
		config.params = restParams;

		const accessToken: string = localStorageUtil.getAccessToken() || '';
		const idToken: string = localStorageUtil.getIdToken() || '';
		const preSignupAuth: string = localStorageUtil.getPreSignupAuth() || '';

		if (accessToken && idToken && config.headers && isTokenRequired) {
			config.headers.Authorization = accessToken;
			config.headers.idToken = idToken;
			if (preSignupAuth) {
				config.headers.presignupauth = preSignupAuth;
			}
		}
		return config;
	},
	error => {
		Promise.reject(error);
	}
);

// Response interceptor for API calls
customAxios.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	(error: any) => {
		const originalRequest: any = error.config;

		if (error.response.status === 401) {
			// && originalRequest.url ===
			// 'http://13.232.130.60:8081/v2/auth/token
			// router.push('/login');
			const redirectTo = window.location.pathname || null;
			const searchParams = window.location.search || '';
			window.location.href =
				window.location.origin +
				'/login' +
				(redirectTo == null ? '' : `?redirectTo=${redirectTo}${searchParams}`);
			return Promise.reject(error);
		}
		if (error?.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const currentUser = UserPool.getCurrentUser();
				if (currentUser) {
					currentUser?.getSession(function (sessionError: any, session: any) {
						if (sessionError) {
							// Logout the user
							window.location.href = window.location.origin + '/login/';
						} else {
							const refreshToken = session.getRefreshToken();
							currentUser.refreshSession(refreshToken, (err: any, sessiontemp: any) => {
								if (err) {
									console.log(err);
									// Logout the user
									window.location.href = window.location.origin + '/login/';
								} else {
									localStorageUtil.setToken({
										accessToken: sessiontemp.getAccessToken().getJwtToken(),
										refreshToken: sessiontemp.getRefreshToken().getToken(),
										idToken: sessiontemp.getIdToken().getJwtToken()
									});
									originalRequest.headers.Authorization = sessiontemp
										.getAccessToken()
										.getJwtToken();
									originalRequest.headers.idToken = sessiontemp.getIdToken().getJwtToken();
									return customAxios(originalRequest);
								}
							});
						}
					});
				} else {
					// Logout the user
					window.location.href = window.location.origin + '/login/';
				}
			} catch (errorRes) {
				console.log('Error', errorRes);
				window.location.href = window.location.origin + '/login/';
			}
		}
		if (Sentry) {
			Sentry.captureException(error);
		}
		return Promise.reject(error);
	}
);
