import React from 'react';
import { Label } from '../Label';
import classes from './FormControl.module.scss';
import { ControlType } from './FormControl.constants';
import { LabelType } from '../Label/Label.constants';

type FormControlProps = {
	children?: React.ReactNode;
	label?: () => React.ReactNode | string;
	error?: (() => React.ReactNode) | string | React.ReactNode;
	caption?: (() => React.ReactNode) | string;
	controlType?: ControlType;
	ariaLabel?: string;
};
export const FormControl = ({
	children,
	label,
	caption,
	error,
	ariaLabel,
	controlType = ControlType.DARK
}: FormControlProps) => {
	let onlyChildProps = null;
	if (React.isValidElement(children)) {
		onlyChildProps = React.Children.only(children)?.props;
	}
	const isHavingError = typeof error === 'function' ? error() : error;
	return (
		<>
			{label && (
				<Label htmlFor={onlyChildProps.id} labelType={controlType as unknown as LabelType}>
					{typeof label === 'function' ? label() : label}
					{ariaLabel && <span aria-label={ariaLabel}></span>}
				</Label>
			)}
			{/* {description && (
				<div className={`${classes['description']} is-size-h5-r mb-10`}>
					{typeof label === 'function' ? description() : description}
				</div>
			)} */}
			<div
				className={`${classes['form-control-container']} ${
					isHavingError ? classes['error-control-container'] : ''
				}`}
			>
				{children}
				{isHavingError && (
					<div
						id={`error${onlyChildProps.id}`}
						role="alert"
						className={
							controlType == ControlType.DARK
								? classes['form-control-error-danger']
								: classes['form-control-error-gray']
						}
					>
						{typeof error === 'function' ? error() : error}
					</div>
				)}
				{!isHavingError && caption && (
					<div
						className={
							controlType == ControlType.DARK
								? classes['form-control-caption-white']
								: classes['form-control-caption-dark']
						}
					>
						{typeof caption === 'function' ? caption() : caption}
					</div>
				)}
			</div>
		</>
	);
};
