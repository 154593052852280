// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgIncomplete = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<g clipPath="url(#Incomplete_svg__a)">
			<path
				fill={props.color || `#25282B`}
				d="m20.487 18.601-6.601-6.6 6.6-6.601a1.332 1.332 0 1 0-1.884-1.885l-6.6 6.601-6.601-6.6A1.333 1.333 0 1 0 3.516 5.4l6.601 6.6-6.6 6.601A1.332 1.332 0 0 0 5.4 20.486l6.6-6.601 6.601 6.6a1.333 1.333 0 0 0 1.885-1.884"
			/>
		</g>
		<defs>
			<clipPath id="Incomplete_svg__a">
				<path fill="#fff" d="M0 0h24.001v24H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgIncomplete;
