import { APIService } from './apiService';

const API_END_POINT = '/v2/user/cards';
const API_END_POINT_CARD = '/v2/user/card';
const API_END_POINT_CARD_DETAIL = '/v2/user/card/digital/';
const API_END_POINT_CHANGE_PIN = '/v2/user/card/pin/change/';
const API_END_POINT_ADD_CARD = '/v2/user/card/add-on-card';
const API_END_CARD_PHONE_UPDATE = '/v2/user/card';

export const cardApi = {
	fetchCards() {
		return APIService.get(API_END_POINT);
	},

	updateCard(patchData: any) {
		return APIService.patch(API_END_POINT_CARD, patchData);
	},

	fetchCardDetail(cardId: number | string) {
		return APIService.get(API_END_POINT_CARD_DETAIL + cardId);
	},

	changePin(cardId: number | string) {
		return APIService.get(API_END_POINT_CHANGE_PIN + cardId);
	},

	addCard(addData: any) {
		return APIService.post(API_END_POINT_ADD_CARD, addData);
	},

	updatePhone(data: any) {
		return APIService.patch(API_END_CARD_PHONE_UPDATE, data);
	}
};
