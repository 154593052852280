import React from 'react';
import classes from './Label.module.scss';
import { LabelType } from './Label.constants';

type LabelProps = {
	htmlFor?: string;
	children: React.ReactNode;
	labelType?: LabelType;
};

export const Label = ({ htmlFor, children, labelType = LabelType.DARK }: LabelProps) => {
	return (
		<label
			className={`${classes['form-control-label']} ${
				labelType == LabelType.DARK ? classes['dark-label'] : classes['light-label']
			}`}
			htmlFor={htmlFor}
		>
			{children}
		</label>
	);
};
