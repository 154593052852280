export const numberWithCommas = (amount: number | string) => {
	return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDecimalVal = (originalVal: number, decimal?: number) => {
	const convertedVal = decimal
		? Number(originalVal || 0).toFixed(decimal)
		: Number(originalVal || 0).toFixed(2);
	return numberWithCommas(convertedVal);
};
