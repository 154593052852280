import { IPaginationConfig } from '../interface/common.interface';
import { APIService } from './apiService';
const DASHBOARD_END_POINT = '/v2/user/account-summary';
const V2_DASHBOARD_END_POINT = '/v2/user/account-summary';
const V2_TRANSACTION_END_POINT = '/v2/user/transactions/';
const ACCOUNT_SUMMARY_END_POINT = '/v2/user/account-detail/';
const TRANSACTION_END_POINT = '/v2/user/transactions/';
const HSA_CONTRIBUTION_END_POINT = '/v2/user/transaction/int/load-money';
const APP_VERSION_END_POINT = '/v2/user/version/support?client=web';

export const dashboardApi = {
	dashboardSummary() {
		return APIService.get(DASHBOARD_END_POINT);
	},

	dashboardSummaryV2() {
		return APIService.get(V2_DASHBOARD_END_POINT);
	},

	getTransactionV2(accountType?: string, paginationConfig?: IPaginationConfig) {
		return APIService.get(`${V2_TRANSACTION_END_POINT}${accountType}`, paginationConfig);
	},

	getAccountDetail(accountType: string) {
		return APIService.get(`${ACCOUNT_SUMMARY_END_POINT}${accountType}`);
	},

	getTransaction(accountType: string, paginationConfig: IPaginationConfig, planId?: string) {
		return APIService.get(
			`${TRANSACTION_END_POINT}${accountType}`,
			planId
				? {
						...paginationConfig,
						plan_id: planId
				  }
				: paginationConfig
		);
	},

	createHsaContribution(postData: any) {
		return APIService.post(HSA_CONTRIBUTION_END_POINT, postData);
	},

	getAppVersion() {
		return APIService.get(APP_VERSION_END_POINT);
	}
};
