import classes from './Badge.module.scss';
import { BadgeTypes } from './Badge.constants';
import React, { SVGProps } from 'react';
import { getClassNames } from '../../../utils/Css';

type BannerProps = {
	type?: string;
	title?: string;
	icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
	onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
	children?: JSX.Element | Array<JSX.Element> | string;
};

export const Badge = ({ type, title, icon: Icon, onFocus, onClick, children }: BannerProps) => {
	// style based on badge type
	const getClassByBannerType = (badgeType: string) => {
		let cssClass = '';
		switch (badgeType) {
			case BadgeTypes.INFO:
				cssClass = classes['badge-info'];
				break;
			case BadgeTypes.POSITIVE:
				cssClass = classes['badge-positive'];
				break;
			case BadgeTypes.WARNING:
				cssClass = classes['badge-warning'];
				break;
			case BadgeTypes.NEGATIVE:
				cssClass = classes['badge-negative'];
				break;
			case BadgeTypes.SECONDARY:
				cssClass = classes['badge-secondary'];
				break;
			default:
				cssClass = classes['badge-info'];
				break;
		}
		return cssClass;
	};

	// Get all the classes of badge by using util
	const classNames = getClassNames(getClassByBannerType(type || BadgeTypes.INFO));

	// Banner JSX code snippet
	return (
		<span className={classNames} onClick={onClick} onFocus={onFocus}>
			{Icon && <span className={classes.icon}>{<Icon width={24} height={24}></Icon>}</span>}
			<span className={classes['badge-content']}>
				{title && <p>{title}</p>}
				{children}
			</span>
		</span>
	);
};
