import { getDecimalVal } from './numberUtil';
import { dateUtil } from './dateUtil';
import { IExpense } from '../interface/common.interface';
import { KeyboardEventHandler } from 'react';

export const groupExpense = (tData: any) => {
	const expenseObj: any = {};
	const expenses: Array<{ sectionName: string; data: Array<IExpense> }> = [];
	tData.forEach((data: IExpense) => {
		const groupName: string = dateUtil.getFormattedDate(new Date(data.date * 1000), 'MMMM YYYY');
		if (expenseObj[groupName]) {
			expenseObj[groupName].push(data);
		} else {
			expenseObj[groupName] = [data];
		}
	});
	Object.keys(expenseObj).forEach((key: string) => {
		expenses.push({ sectionName: key, data: expenseObj[key] });
	});

	return [
		...expenses.map((expense: { sectionName: string; data: Array<IExpense> }) => {
			expense.data = expense.data.map((expenseCol: any) => {
				expenseCol.date = dateUtil.getFormattedDate(
					new Date(expenseCol.date * 1000),
					'MMMM DD, YYYY'
				);

				expenseCol.amount = `$${getDecimalVal(expenseCol.amount)}`;
				return expenseCol;
			});
			return expense;
		})
	];
};

export const handleKeyDown: KeyboardEventHandler = (e: React.KeyboardEvent<HTMLElement>) => {
	if (e.key == 'Enter' || e.key == ' ') {
		e.preventDefault();
		e.currentTarget.click();
	}
};
