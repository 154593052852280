import React, { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { IconColor } from '../../../constants/common';
import { Clear, Complete } from '../../icons';
import classes from './PasswordHint.module.scss';

type PasswordHintProps = {
	control: any;
	fieldName?: string;
};
export const PasswordHint = ({ control, fieldName = 'password' }: PasswordHintProps) => {
	const password = useWatch({
		control,
		name: fieldName
	});
	const [liveMessage, setLiveMessage] = useState('');

	const errorCondition = useMemo(
		() => [
			{ text: 'Contains capital letter', error: true, pattern: '' },
			{ text: 'Contains lowercase letter', error: true, pattern: '' },
			{ text: 'Contains number', error: true, pattern: '' },
			{ text: '8 characters or more', error: true, pattern: '' },
			{ text: 'Special character required', error: true, pattern: '' }
		],
		[]
	);

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		let passedCheckCount = 0;
		if (password || typeof password === 'string') {
			if (!password.match(/[A-Z]/g)) {
				errorCondition[0].error = true;
			} else {
				errorCondition[0].error = false;
				setLiveMessage(errorCondition[0].text);
				passedCheckCount = passedCheckCount + 1;
			}
			if (!password.match(/[a-z]/g)) {
				errorCondition[1].error = true;
			} else {
				errorCondition[1].error = false;
				setLiveMessage(errorCondition[1].text);
				passedCheckCount = passedCheckCount + 1;
			}
			if (!password.match(/[0-9]/g)) {
				errorCondition[2].error = true;
			} else {
				errorCondition[2].error = false;
				setLiveMessage(errorCondition[2].text);
				passedCheckCount = passedCheckCount + 1;
			}
			if (!(password.length >= 8)) {
				errorCondition[3].error = true;
			} else {
				errorCondition[3].error = false;
				setLiveMessage(errorCondition[3].text);
				passedCheckCount = passedCheckCount + 1;
			}
			if (!password.match(/(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=(.*))./g)) {
				errorCondition[4].error = true;
			} else {
				setLiveMessage(errorCondition[4].text);
				errorCondition[4].error = false;
				passedCheckCount = passedCheckCount + 1;
			}
		}
		if (passedCheckCount === 5) {
			setLiveMessage('All password checks');
			setTimeout(() => {
				setLiveMessage('');
			}, 3000);
		}
	}, [password, errorCondition]);
	return (
		<div className={classes.container}>
			{liveMessage && (
				<div aria-live="assertive" aria-atomic="true" className="visually-hidden">
					Passed the check for :{liveMessage}
				</div>
			)}
			{errorCondition.map((value, index) => (
				<span className={classes['error-list']} key={index} tabIndex={0}>
					{value.error ? (
						<Clear
							height={24}
							width={24}
							color={IconColor.error}
							title="Check failed for -"
							titleId={`cross-${index}`}
						/>
					) : (
						<span className={classes['tick-icon']}>
							<Complete
								height={16}
								width={16}
								color={'#111827'}
								title="Check passed for -"
								titleId={`check-${index}`}
							/>
						</span>
					)}{' '}
					<span> {value.text}</span>
				</span>
			))}
		</div>
	);
};
