export const customModalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '100%',
		maxWidth: '560px',
		transform: 'translate(-50%, -50%)',
		padding: '0px',
		border: 'none'
	}
};
