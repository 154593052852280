interface IToken {
	accessToken: string;
	refreshToken?: string;
	// auth_token?: string;
	idToken?: string;
}
export const localStorageUtil = {
	setToken(tokenObj: IToken) {
		localStorage.setItem('accessToken', tokenObj.accessToken);
		localStorage.setItem('refreshToken', tokenObj.refreshToken || '');
		// localStorage.setItem('auth_token', tokenObj.auth_token || '');
		localStorage.setItem('idToken', tokenObj.idToken || '');
	},
	getAccessToken() {
		return localStorage.getItem('accessToken');
	},
	getRefreshToken() {
		return localStorage.getItem('refreshToken');
	},
	getPreSignupAuth() {
		return localStorage.getItem('presignupauth');
	},
	setPreSignupAuth(presignupauth: string) {
		localStorage.setItem('presignupauth', presignupauth || '');
	},

	getIdToken() {
		return localStorage.getItem('idToken');
	},
	removeToken(key: string) {
		localStorage.removeItem(key);
	},
	clearTokens() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		// localStorage.removeItem('auth_token');
		localStorage.removeItem('idToken');
	}
};
