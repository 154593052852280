import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { dashboardApi } from '../services/dashboardService';

export const getAccountSummary = createAsyncThunk('user/account-summary', () => {
	return dashboardApi.dashboardSummaryV2().then(response => response.data);
});

const accountSummarySlice = createSlice<
	{
		loading: boolean;
		data: {
			xfsa_summary: Array<Record<string, unknown>>;
			hsa_summary: Array<Record<string, unknown>>;
			external_account_linked: boolean;
			expense_count: number;
		};
		error: string;
	},
	Record<string, never>,
	'accountSummarySlice'
>({
	name: 'accountSummarySlice',
	initialState: {
		loading: true,
		data: {
			//summary: [],
			xfsa_summary: [],
			hsa_summary: [],
			external_account_linked: false,
			expense_count: 0
		},
		error: ''
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getAccountSummary.pending, state => {
			state.loading = true;
		});
		builder.addCase(getAccountSummary.fulfilled, (state, action) => {
			state.loading = false;
			state.data = action.payload;
		});
		builder.addCase(getAccountSummary.rejected, (state, action) => {
			state.loading = false;
			state.error = action.error.message || 'Something went wrong';
		});
	}
});

export default accountSummarySlice.reducer;
