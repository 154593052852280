// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgShow = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M12 5C5.37 5 0 11.911 0 11.911S5.371 19 12 19s12-7.089 12-7.089S18.624 5 12 5m0 12.005a5.44 5.44 0 0 1-2.918-.843 5.07 5.07 0 0 1-1.935-2.246 4.8 4.8 0 0 1-.3-2.892 4.94 4.94 0 0 1 1.438-2.562 5.34 5.34 0 0 1 2.689-1.37 5.5 5.5 0 0 1 3.035.284c.96.38 1.78 1.02 2.357 1.844a4.798 4.798 0 0 1 .486 4.696 5 5 0 0 1-1.138 1.623 5.3 5.3 0 0 1-1.704 1.085c-.637.251-1.32.38-2.01.38"
		/>
		<path
			fill={props.color || `#25282B`}
			d="M13.238 11.466a1.151 1.151 0 1 0 0-2.302 1.151 1.151 0 0 0 0 2.302"
		/>
	</svg>
);
export default SvgShow;
