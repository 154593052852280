/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, ButtonTypes } from '../../../components/common/Button';
import classes from './EditPhoneNumber.module.scss';
// import { cardApi } from '../../../services/cardApi';
import { FormControl } from '../../../components/common/FormControl';
import { Input, InputTypes } from '../../../components/common/Input';
import { dateUtil } from '../../../utils/dateUtil';
import { Banner, BannerTypes } from '../../../components/common/Banner';
import { Incomplete, Warning } from '../../../components/icons';
import verifyphoneImage from '../../../assets/img/PhoneCheckLight.svg';
import { StyledLink, StyledLinkSizes } from '../../../components/common/Link';
import ReactInputVerificationCode from 'react-input-verification-code';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from '../../../utils/UserPool';
import { contactApi } from '../../../services/profileService';
import { Loader } from '../../../components/common/Loader';
import { StyledLinkTypes } from '../Link/StyledLink.constants';
import { ControlType } from '../FormControl/FormControl.constants';

type PhoneFormValues = {
	phone_number: string;
};
type PasscodeFormValues = {
	passcode: string;
};

export const EditPhoneNumber = ({
	successModal,
	nextModal,
	closeModal,
	canSkip = true,
	closeIcon = true,
	title = '',
	description = '',
	//isFromOnboarding = false,
	currentPhoneNumber = ''
}: {
	successModal: () => void;
	closeModal: () => void;
	nextModal: () => void;
	canSkip?: boolean;
	closeIcon?: boolean;
	title?: string;
	description?: string;
	//isFromOnboarding?: boolean;
	currentPhoneNumber?: string;
}) => {
	const [customPhoneError, setCustomPhoneError] = useState({ code: '', message: '' });
	const [customPasscodeError, setCustomPasscodeError] = useState({ code: '', message: '' });
	const [numberAdded, setNumberAdded] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);

	const {
		watch: phoneWatch,
		control: phoneControl,
		setValue: setPhoneValue,
		getValues: getPhoneValue,
		handleSubmit: handlePhoneSubmit,
		formState: { isValid: isPhoneValid }
	} = useForm<PhoneFormValues>({
		mode: 'onChange',
		defaultValues: {
			phone_number: ''
		}
	});

	const {
		control: passcodeControl,
		handleSubmit: handlePasscodeSubmit,
		reset: passcodeReset,
		formState: { isValid: isPasscodeValid }
	} = useForm<PasscodeFormValues>({
		mode: 'onChange',
		defaultValues: {
			passcode: ''
		}
	});

	phoneWatch('phone_number');

	// useEffect(() => {
	// 	if (isFromOnboarding) {
	// 		(window as any).gtag('event', 'page_view', {
	// 			app_name: 'Zenda-web',
	// 			page_title: 'Onboarding_phone_verification',
	// 			// screen_name: 'Onboarding_work_email_confirm',
	// 			send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 		});
	// 	}
	// }, [isFromOnboarding]);

	const updatePhone = async (phoneNumber: string) => {
		new Promise((resolve, reject) => {
			contactApi
				.updatePhoneNumber({
					phone_number: phoneNumber,
					sent_at: dateUtil.getEpochTime()
				})
				.then((updated: any) => {
					resolve(updated);
				})
				.catch((error: any) => {
					reject(error.response.data.error);
				});
		});
	};

	const onPhoneSubmit = handlePhoneSubmit((data: PhoneFormValues) => {
		const phoneNumber = data.phone_number.split('-').join('');
		if (phoneNumber.length < 10 || (currentPhoneNumber && currentPhoneNumber == phoneNumber)) {
			return;
		}
		setLoading(true);
		setCustomPhoneError({ code: '', message: '' });
		setCustomPasscodeError({ code: '', message: '' });
		const currentUser = UserPool.getCurrentUser();
		currentUser?.getSession(function (sessionError: any) {
			if (!sessionError) {
				const attributeList = [];
				let attribute = {
					Name: 'phone_number',
					Value: `+1${phoneNumber}`
				};
				attribute = new CognitoUserAttribute(attribute);
				attributeList.push(attribute);

				currentUser?.updateAttributes(attributeList, function (updateError) {
					if (updateError) {
						alert(updateError.message || JSON.stringify(updateError));
						setLoading(false);
						setCustomPhoneError({ code: '', message: updateError.message });
						return;
					}
					currentUser.getAttributeVerificationCode('phone_number', {
						onSuccess: function () {
							setLoading(false);
							passcodeReset();
							setNumberAdded(true);
						},
						onFailure: function (error) {
							alert(error.message || JSON.stringify(error));
							setLoading(false);
							setCustomPhoneError({ code: '', message: error.message });
						}
					});
				});
			} else {
				console.log('session error', sessionError);
			}
		});
	});

	const sendPasscode = () => {
		const currentUser = UserPool.getCurrentUser();
		currentUser?.getSession(function (sessionError: any) {
			if (!sessionError) {
				currentUser.getAttributeVerificationCode('phone_number', {
					onSuccess: function () {
						setLoading(false);
						passcodeReset();
						setNumberAdded(true);
					},
					onFailure: function (error) {
						alert(error.message || JSON.stringify(error));
						setLoading(false);
						setCustomPhoneError({ code: '', message: error.message });
					}
				});
			} else {
				console.log('session error', sessionError);
			}
		});
	};

	const onPasscodeSubmit = handlePasscodeSubmit(async (data: PasscodeFormValues) => {
		setLoading(true);
		setCustomPasscodeError({ code: '', message: '' });
		const currentUser = UserPool.getCurrentUser();
		currentUser?.getSession(function (sessionError: any) {
			if (!sessionError) {
				currentUser.verifyAttribute('phone_number', data.passcode, {
					async onSuccess() {
						try {
							await updatePhone(getPhoneValue('phone_number').split('-').join(''));
							successModal();
						} catch (phoneUpdateError: any) {
							setCustomPasscodeError({ code: '', message: phoneUpdateError.message });
							closeModal();
						}
					},
					onFailure(verifyError) {
						setLoading(false);
						setCustomPasscodeError({ code: '', message: verifyError.message });
					}
				});
			} else {
				console.log('session error', sessionError);
			}
		});
	});
	// Check the phone number is valid or not - it should be 10 digit
	const validatePhone = (): boolean => {
		return (getPhoneValue('phone_number').split('-').join('').length == 10 && true) || false;
	};
	if (isLoading) {
		return <Loader />;
	}

	return (
		<div className={``}>
			<div className="modal-header">
				{closeIcon && (
					<div onClick={closeModal} className="modal-close-btn-container">
						<Incomplete></Incomplete>
					</div>
				)}
				<div className={`text-h3-325 mt-32 text-white text-center`}>
					{`${title || 'Use your Zenda Cards in mobile wallets'}`}
				</div>
			</div>
			<div className="modal-body d-flex flex-column align-items-center">
				{!numberAdded && (
					<React.Fragment>
						<img width={200} className="mt-24" src={verifyphoneImage} />

						<React.Fragment>
							<br />
							<div className={`${classes.address} ${classes['center-content']}`}>
								{customPhoneError && customPhoneError.message && (
									<span className={classes.banner}>
										<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
											{customPhoneError.message}
										</Banner>
									</span>
								)}
							</div>
						</React.Fragment>

						{description ? (
							''
						) : (
							<div className={`${classes['center-content']} ${classes['text-center']}`}>
								<p className="text-base-700 pb-16 text-primary">Your Zenda card is now active!</p>
							</div>
						)}

						<form onSubmit={onPhoneSubmit}>
							<p className={'text-base-325 text-primary text-center'}>
								{`${
									description ||
									'To use your Zenda card in  mobile wallets you will need to  verify a mobile phone number.'
								}`}
							</p>
							<br />
							<div>
								<Controller
									control={phoneControl}
									name="phone_number"
									rules={{
										validate: validatePhone
									}}
									render={({ field: { onChange, value } }) => (
										<>
											<FormControl
												label={() => 'Mobile Phone Number on File'}
												error={
													currentPhoneNumber && currentPhoneNumber == value.split('-').join('')
														? `Phone number already in use.`
														: ``
												}
												controlType={ControlType.DARK}
											>
												<Input
													onchange={evt => {
														const phoneNumber = evt.target.value.split('-').join('');

														if (!/^[0-9]*\.?[0-9]*$/.test(phoneNumber)) {
															evt.preventDefault();
														} else {
															evt.target.value =
																phoneNumber.length <= 6
																	? phoneNumber.replace(/(\d{3})(\d{1})/, '$1-$2')
																	: phoneNumber.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3');
															onChange(evt);
														}
													}}
													onClear={() => {
														setPhoneValue('phone_number', '');
													}}
													isClearable={true}
													type={InputTypes.TEXT}
													value={value}
													placeholder={'Phone Number'}
													maxLength={12}
													isCompleted={isPhoneValid}
												/>
											</FormControl>
										</>
									)}
								/>
								<p className={'text-sm-325 text-primary'}>
									By clicking "Send Code", you agree to receive a one-time SMS from InComm Benefits.
									Message and data rates may apply.
								</p>
							</div>
							<br />
							<div className={`${classes['button-group-col']} ${classes['center-content']}`}>
								<Button
									type={ButtonTypes.PRIMARY}
									isDisabled={
										!isPhoneValid ||
										(!!currentPhoneNumber &&
											currentPhoneNumber == getPhoneValue('phone_number').split('-').join(''))
									}
								>
									Send Code
								</Button>
								{canSkip && (
									<StyledLink
										type={StyledLinkTypes.TERTIARY}
										size={StyledLinkSizes.SM}
										onClick={nextModal}
									>
										Skip this step
									</StyledLink>
								)}
							</div>
						</form>
					</React.Fragment>
				)}
				{numberAdded && (
					<React.Fragment>
						<div className={classes['center-content']}>
							<div className={`text-h3-325  text-white mt-32`}>Verify your phone number</div>

							<img width={200} className="mt-12" src={verifyphoneImage} />

							<React.Fragment>
								<div className={`${classes.address} ${classes['center-content']}`}>
									{customPasscodeError && customPasscodeError.message && (
										<span className={classes.banner}>
											<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
												{customPasscodeError.message}
											</Banner>
										</span>
									)}
								</div>
							</React.Fragment>

							<p className={'text-base-700 py-24 text-primary'}>We sent a verification code to</p>
							<p className="text-h4 text-primary">{getPhoneValue('phone_number')}</p>
							<p className="pb-24 text-primary">
								Number not correct?{' '}
								<StyledLink
									onClick={() => {
										setNumberAdded(false);
									}}
									type={StyledLinkTypes.TERTIARY}
								>
									Update
								</StyledLink>
							</p>
							<form onSubmit={onPasscodeSubmit} autoComplete="off" className={classes['w-100']}>
								<Controller
									control={passcodeControl}
									name="passcode"
									rules={{
										required: 'Passcode is required.',
										minLength: { value: 6, message: 'Please enter 6 digit Passcode.' }
									}}
									render={({ field: { onChange, value }, fieldState: {} }) => (
										<>
											<FormControl
												label={() => 'Your 6 Digit Code'}
												controlType={ControlType.LIGHT}
											>
												<ReactInputVerificationCode
													value={value}
													length={6}
													placeholder={''}
													onChange={onChange}
													autoFocus={true}
												/>
											</FormControl>
										</>
									)}
								/>
								<div className={classes['center-content']}>
									<Button type={ButtonTypes.PRIMARY} isDisabled={!isPasscodeValid}>
										Verify
									</Button>
								</div>
							</form>
							<br />

							<p className="pt-16 text-primary">
								Didn’t get a verification code?{' '}
								<StyledLink type={StyledLinkTypes.TERTIARY} onClick={sendPasscode}>
									Send again
								</StyledLink>
							</p>
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};
