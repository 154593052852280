// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgAttachment = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 33 33"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M10.727 32.296a10.7 10.7 0 0 1-7.31-2.851 9.56 9.56 0 0 1 0-14.037l13.817-13.05a7.713 7.713 0 0 1 10.4 0 7.5 7.5 0 0 1 2.394 5.374 6.32 6.32 0 0 1-2.01 4.66L14.181 25.46a4.514 4.514 0 0 1-6.141 0 4.076 4.076 0 0 1 0-5.995L20.834 7.421a1.371 1.371 0 0 1 1.938 1.938L9.978 21.403a1.334 1.334 0 0 0 0 2.01 1.83 1.83 0 0 0 2.376 0L26.19 10.382a3.66 3.66 0 0 0 1.096-2.65 4.7 4.7 0 0 0-1.535-3.363 4.88 4.88 0 0 0-6.635 0L5.354 17.4a6.817 6.817 0 0 0 0 10.052 8.04 8.04 0 0 0 10.966 0l13.69-12.958a1.354 1.354 0 0 1 1.937 0 1.37 1.37 0 0 1 0 1.938l-13.8 13.013a10.67 10.67 0 0 1-7.42 2.85"
		/>
	</svg>
);
export default SvgAttachment;
