import React from 'react';
import { RadioSizes } from './Radio.constants';
import classes from './Radio.module.scss';
import { getClassNames } from '../../../utils/Css';

type RadioProps = {
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	value?: string;
	name?: string;
	size?: RadioSizes;
	isDisabled?: boolean;
	children?: React.ReactNode;
};
export const Radio = ({
	checked,
	isDisabled = false,
	value = '',
	name = '',
	size = RadioSizes.DEFAULT,
	children,
	onChange
}: RadioProps) => {
	const classNames = getClassNames(
		classes.box,
		checked && classes.checked,
		isDisabled && classes.disabled
	);

	const classNamesForLabel = getClassNames(
		classes.label,
		isDisabled && classes.disabled,
		'is-size-h4-r',
		'd-flex',
		'align-items-center'
	);

	const getRadioSize = (radioSize: string) => {
		let cssClass = '';
		switch (radioSize) {
			case RadioSizes.MEDIUM:
				cssClass = classes['medium-radio'];
				break;
			case RadioSizes.LARGE:
				cssClass = classes['large-radio'];
				break;
			default:
				break;
		}
		return cssClass;
	};

	return (
		<label className={classNamesForLabel}>
			<div>
				<span className={classNames}></span>
				<input
					type="radio"
					disabled={isDisabled}
					checked={checked}
					value={value}
					name={name}
					onChange={onChange}
					className={getRadioSize(size)}
				/>
			</div>
			<div>{children}</div>
		</label>
	);
};
