import day from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';
import minMax from 'dayjs/plugin/minMax';

day.extend(isBetween);
day.extend(utc);
day.extend(minMax);

export const dateUtil = {
	/**
	 * Returns a epoch time
	 *  if date passed as parameter then based on date else current date and time
	 * for ex., 1. `getEpochTime(new Date())`
	 *
	 * @param   { date }  takes date or date string
	 * @returns { number }          return number as epoch time
	 */
	getEpochTime: (date?: Date | string) => {
		return date ? new Date(date).valueOf() : new Date().valueOf();
	},
	/**
	 * Returns a formatted date
	 *  if date and format is passed as parameter then based on date and format else current date and YYYY-MM-DD format
	 * for ex., 1. `getFormatted(Date(new Date(),'YYYY-MM-DD')`
	 *
	 * @param   { date , string }  takes date as date or string  and format as string
	 * @returns {string }          return string as formatted date
	 */
	getFormattedDate: (date?: Date | string | number, format?: string) => {
		return date ? day(date).format(format || 'YYYY-MM-DD') : day().format(format || 'YYYY-MM-DD');
	},
	/**
	 * Returns a formatted month
	 *  if date and format is passed as parameter then based on date and format else current date and YYYY-MM-DD format
	 * for ex., 1. `getFormatted(number,'MMM')`
	 *
	 * @param   { number , string }  takes date as date or string and format as string
	 * @returns {string }          return string as formatted date
	 */
	getFormattedMonth: (month: number) => {
		const date = new Date();
		date.setMonth(month - 1);
		return date.toLocaleString('en-US', {
			month: 'long'
		});
	},

	/**
	 * Returns a formatted date
	 *  if date is passed as parameter then maintain same date and time in different timezone
	 * for ex., 1. `getFormatted(new Date())`
	 *
	 * @param   { date , string }  takes date as date or string
	 * @returns {string }          return string as formatted date
	 */
	getDate: (date: string | Date, keepLocalTime = false) => {
		return day(date).utcOffset(day().utcOffset(), keepLocalTime).format();
	},
	/**
	 * Returns a day js instanc
	 */
	day
};
