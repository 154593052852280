/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './ExpensePayBackPage.module.scss';

import Select from 'react-select';
import Modal from 'react-modal';
import { useForm, Controller } from 'react-hook-form';
import { FormControl } from '../../components/common/FormControl';
import {
	getSelectStyle,
	ReimburseTo,
	AccountType,
	ExpenseActionType
} from '../../constants/common';
import { ChevronClose, Incomplete } from '../../components/icons';
import { Button, ButtonSizes, ButtonTypes } from '../../components/common/Button';
import { getDecimalVal } from '../../utils/numberUtil';
import { dateUtil } from '../../utils/dateUtil';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { expenseApi } from '../../services/expenseService';
import { Loader } from '../../components/common/Loader';

import { useDispatch, useSelector } from 'react-redux';
import { getAccountSummary } from '../../store/accountSummarySlice';
import { usePlaid } from '../../hooks/usePlaid';
import { StyledLink } from '../../components/common/Link';

import linkBankAccountError from '../../assets/img/error.svg';
import ConfirmationImage from '../../assets/img/ThumbsUpLight.svg';
import { ContactUs } from '../../components/ContactUs';
import { StyledLinkTypes } from '../../components/common/Link/StyledLink.constants';

type FormValues = {
	from: string;
	to: string;
};

export const ExpensePayBackPage = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [expenseDetails, setExpenseDetails] = useState<any>({});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [toOptions, setToOptions] = useState<Array<{ label: string; value: string }>>([]);
	const [fromOptions, setFromOptions] = useState<Array<{ label: string; value: string }>>([]);
	const [everydayData, setEverydayData] = useState<any>({});
	const { openPlaid, networkResponse, networkError, networkLoading } = usePlaid('bank-account');

	const [invalidAccountModalIsOpen, setInvalidAccountModalIsOpen] = useState<boolean>(false);
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [linkSuccessModalIsOpen, setLinkSuccessModalIsOpen] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);

	const dispatch = useDispatch<any>();
	const summaryData = useSelector((state: any) => state.summaryData);

	const expenseId = searchParams.get('id');

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '560px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none'
			// padding: '56px 24px',
			// backgroundColor: '#001b47',
			// borderColor: '#001b47'
		}
	};

	const {
		setValue,
		control,
		handleSubmit,
		watch,
		formState: {}
	} = useForm<FormValues>({
		mode: 'onBlur',
		defaultValues: {
			from: '',
			to: ''
		}
	});

	const fromInput = watch('from');

	const getExpenseDetails = () => {
		if (!expenseId) {
			return;
		}
		setIsLoading(true);
		expenseApi
			.getExpense(expenseId)
			.then((response: any) => {
				dispatch(getAccountSummary());
				setExpenseDetails(response.data);

				const paidFromAccount =
					response?.data?.account_breakdown?.payment_details?.paid_from_accounts?.filter(
						(account: { account: string }) => account.account.toLowerCase() !== AccountType.EVERYDAY
					);
				setToOptions([
					{
						label: `Account ${paidFromAccount?.[0]?.account || ''}`,
						value: paidFromAccount?.[0]?.account || ''
					}
				]);

				setIsLoading(false);
			})
			.catch(() => {
				navigate('/apps/expenses/summary');
			});
	};

	const getButtonText = () => {
		const expense = expenseDetails?.action_list?.find(
			(item: { action: string }) => item.action == ExpenseActionType.PAYBACK
		);
		return expense?.text || 'Pay Back';
	};

	const getPaybackAmount = () => {
		const expense = expenseDetails?.action_list?.find(
			(item: { action: string }) => item.action == ExpenseActionType.PAYBACK
		);
		return expense?.text?.split('$')[1] || expenseDetails?.expense_summary?.total_amount;
	};

	useEffect(() => {
		if (!summaryData.loading) {
			const options = [];

			const everydayAccount = summaryData.data?.hsa_summary.find(
				(account: { type: string }) => account.type == AccountType.EVERYDAY
			);
			if (everydayAccount && everydayAccount.balance >= Number(getPaybackAmount())) {
				options.push({
					label: 'Everyday Account',
					value: ReimburseTo.EVERYDAY,
					amount: everydayAccount.balance
				});
				setEverydayData(everydayAccount);
			}

			if (!summaryData.data.external_account_linked) {
				options.push({ label: 'LINK', value: 'LINK' });
			} else {
				options.push({ label: 'Linked Bank Account', value: ReimburseTo.EXTERNAL });
			}

			if (options.length) {
				setValue('from', options[0].value);
			}
			setFromOptions(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summaryData.loading]);

	useEffect(() => {
		if (!expenseId) {
			navigate('/apps/expenses/summary');
			return;
		}
		// (window as any).gtag('event', 'screen_view', {
		// 	app_name: 'Zenda-web',
		// 	screen_name: 'Expense'
		// });
		getExpenseDetails();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (networkError && networkError.response.data.error.code == 'NAME_DOES_NOT_MATCH') {
			setInvalidAccountModalIsOpen(true);
		}
	}, [networkError]);

	useEffect(() => {
		if (networkResponse) {
			setLinkSuccessModalIsOpen(true);
		}
	}, [networkResponse]);

	const payback = (paybackFrom: string) => {
		setIsLoading(true);
		expenseApi
			.reImburseExpense(expenseDetails.expense_id, {
				type: 'payback',
				payback_from: paybackFrom,
				sent_at: dateUtil.getEpochTime()
			})
			.then(() => {
				setIsLoading(false);
				setSubmitted(true);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const onSubmit = handleSubmit((data: FormValues) => {
		payback(data.from);
	});

	const formatFromOptionLabel = ({ value, label }: { value: string; label: string }) => (
		<div className="d-flex justify-content-between align-items-center ">
			<div>
				{label == 'LINK'
					? 'External Account'
					: value == ReimburseTo.EVERYDAY
					? label.split('$')[0]
					: label}
			</div>
			{label == 'LINK' ? (
				<div className={`d-flex align-items-center text-base-700`}>
					<Button
						type={ButtonTypes.TERTIARY}
						size={ButtonSizes.COMPACT}
						onClick={e => {
							e.preventDefault();
							openPlaid();
						}}
					>
						Link Account
					</Button>
				</div>
			) : (
				''
			)}
			{value == ReimburseTo.EVERYDAY ? (
				<div className={`d-flex align-items-center `}>${everydayData.balance || '00.00'}</div>
			) : (
				''
			)}
		</div>
	);

	if (isLoading || summaryData.loading || networkLoading) {
		return <Loader />;
	}

	return (
		<>
			<Modal
				isOpen={invalidAccountModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setInvalidAccountModalIsOpen(false);
				}}
				contentLabel="Link Error Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setInvalidAccountModalIsOpen(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-white text-center mt-32 text-h3-325`}>UH OH!</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={linkBankAccountError} width="180px"></img>

						<p className={`px-64 py-16 text-base-325 text-primary`}>
							The name on the bank account you are trying to link does not match the name on your
							InComm account.
						</p>
						<p className={`px-64 pt-4 text-base-325 text-primary`}>
							For security purposes we aren’t able to link accounts unless account names match
							exactly.
						</p>
						<div className={`d-flex flex-row flex mt-32 gap-20`}>
							<Button
								type={ButtonTypes.PRIMARY}
								onClick={() => {
									setInvalidAccountModalIsOpen(false);
								}}
							>
								TRY ANOTHER ACCOUNT
							</Button>
						</div>
						<p className={'py-16 text-base-325 text-primary'}>
							Need Help?{' '}
							<StyledLink
								type={StyledLinkTypes.PRIMARY}
								//href="mailto:support@incommbenefits.com"
								onClick={() => {
									setIsContactUsOpen(true);
								}}
							>
								Contact Us
							</StyledLink>
						</p>
					</div>
				</div>
			</Modal>
			<Modal
				isOpen={linkSuccessModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setLinkSuccessModalIsOpen(false);
				}}
				contentLabel="Link Success Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div className="">
					<div className="modal-header">
						<div
							onClick={() => {
								setLinkSuccessModalIsOpen(false);
								getExpenseDetails();
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h3-325 text-white text-center mt-32`}>Success!</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<img src={ConfirmationImage} width="180px"></img>
						<p className={`px-64 py-16 text-base-325 text-primary`}>
							Your bank account is now linked to InComm Benefits! Now we need you to fund your
							spending account in order to fund your regular purchases.
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								type={ButtonTypes.PRIMARY}
								onClick={() => {
									setLinkSuccessModalIsOpen(false);
									getExpenseDetails();
								}}
							>
								Continue
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<ContactUs
				openModal={isContactUsOpen}
				closeModal={() => {
					setIsContactUsOpen(false);
				}}
				eventPage="Expense - Pay Back - Name Mismatch"
			/>
			{submitted ? (
				<div className="mt-64">
					<div className="d-flex flex-column align-items-center mt-128">
						<p className={`text-h1-325 ${classes['orange-text']}`}>Payback Submitted!</p>
						<img
							className={`${classes['success-image']} py-16`}
							width={236}
							height={236}
							src={ConfirmationImage}
						/>

						<>
							<p className="text-sm-325 mt-12">
								Please allow up to 3 business days for your payback to be processed.
							</p>
							<hr />
						</>

						<div className={`${classes['sm-reverse']} row w-100  justify-content-center`}>
							<div className=" d-flex flex-column cl-lg-4  cl-md-6 pt-24">
								<Button
									type={ButtonTypes.SECONDARY}
									onClick={() => {
										navigate('/apps/expenses/summary');
									}}
								>
									Return to Dashboard
								</Button>
							</div>
							<div className=" d-flex flex-column cl-lg-4 cl-md-6 pt-24">
								<Button
									onClick={e => {
										e.preventDefault();
										navigate(`/apps/expense?id=${searchParams.get('id')}&isClearRoute=true`);
									}}
								>
									View Expense
								</Button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="mt-64">
						<div className="d-flex align-items-center">
							<div
								className={`d-flex align-items-center ${classes['close-icon']}  px-4`}
								onClick={() => {
									navigate('/apps/expenses/summary');
								}}
							>
								<ChevronClose color="#eff3fa" />
							</div>
							<div
								className={`d-flex justify-content-center align-items-center w-100 ${classes['expense-header']}`}
							>
								<p className={classes['header-text']}>Pay Back an Expense</p>
							</div>
						</div>
					</div>
					<div className="d-flex flex-column justify-content-center mt-64">
						<div className="row mt-32">
							<div className={`cl-md-6 cl-12 `}>
								<div className="mt-16">
									<p className={`${classes['h4-bold']}`}>Expense Breakdown</p>
									<div className="py-32">
										<div className="d-flex justify-content-between ">
											<p className="text-base-700">Transaction Amount</p>
											<p className="text-base-700">
												${getDecimalVal(expenseDetails?.expense_summary?.total_amount)}
											</p>
										</div>
										<div className="d-flex justify-content-between ">
											<p>Eligible Amount</p>
											<p>
												{expenseDetails?.expense_summary?.eligible_amount == null
													? 'Pending Review'
													: `$${getDecimalVal(expenseDetails?.expense_summary?.eligible_amount)}`}
											</p>
										</div>
										<div className="d-flex justify-content-between">
											<p>Ineligible Amount</p>
											<p>
												{expenseDetails?.expense_summary?.ineligible_amount == null
													? 'Pending Review'
													: `$${getDecimalVal(expenseDetails?.expense_summary?.ineligible_amount)}`}
											</p>
										</div>
										<hr />
										<div className="d-flex justify-content-between py-12">
											<p className="text-base-700">Amount to Pay Back</p>
											<p className="text-base-700">${getDecimalVal(getPaybackAmount())}</p>
										</div>
										<hr />
									</div>
								</div>
							</div>
							<div className="cl-12">
								<div>
									<form onSubmit={onSubmit} className="py-16">
										<div className="d-flex flex-column">
											<div className="row">
												<div className="cl-md-6">
													<Controller
														control={control}
														name="from"
														rules={{
															required: 'From is required.'
														}}
														render={({ field: { value }, fieldState: { error } }) => (
															<>
																<FormControl
																	label={() => 'From'}
																	error={() => error?.message || ''}
																	// caption={() => {
																	// 	if (getValues('from') == ReimburseTo.EVERYDAY) {
																	// 		return `Balance after processing: $${getDecimalVal(
																	// 			everydayData.amount -
																	// 				expenseDetails?.expense_summary?.total_amount
																	// 		)}`;
																	// 	} else {
																	// 		return '';
																	// 	}
																	// }}
																>
																	<Select
																		options={fromOptions}
																		onChange={selected => {
																			setValue('from', selected?.value || '');
																		}}
																		placeholder={'Select Account'}
																		value={
																			value ? fromOptions.find(item => item.value == value) : null
																		}
																		isSearchable={false}
																		styles={{
																			control: getSelectStyle
																		}}
																		formatOptionLabel={formatFromOptionLabel}
																	/>
																</FormControl>
															</>
														)}
													/>
												</div>

												<div className="cl-md-6">
													<div>
														<Controller
															control={control}
															name="to"
															render={({ field: {}, fieldState: { error } }) => (
																<>
																	<FormControl
																		label={() => 'To'}
																		error={() => error?.message || ''}
																	>
																		<Select
																			options={toOptions}
																			placeholder={'Select Account'}
																			value={toOptions.length ? toOptions[0] : null}
																			isSearchable={false}
																			styles={{
																				control: getSelectStyle
																			}}
																			isDisabled={true}
																		/>
																	</FormControl>
																</>
															)}
														/>
													</div>
												</div>
												<div className="cl-md-6"></div>
											</div>

											<hr />
											<div className={`row ${classes['sm-reverse']}`}>
												<div className="cl-md-6 d-flex flex-column py-8">
													<Button
														onClick={e => {
															e.preventDefault();
															navigate(
																`/apps/expense?id=${searchParams.get('id')}&isClearRoute=true`
															);
														}}
														type={ButtonTypes.SECONDARY}
													>
														Cancel
													</Button>
												</div>
												<div className="cl-md-6 d-flex flex-column py-8">
													<Button isDisabled={fromInput == 'LINK'}>{getButtonText()}</Button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
};
