import React, { useState } from 'react';
import classes from './ForgotPasswordPage.module.scss';
import { EmailCheck } from '../../components/ForgotPassword/EmailCheck/EmailCheck';
import { ForgotPasswordData } from '../../types/forgotPassword.types';
import { PasscodeVerification } from '../../components/ForgotPassword/PasscodeVerification';
import { Helmet } from 'react-helmet';
// import { NewPassword } from '../../components/ForgotPassword/NewPassword/NewPassword';
// import { useNavigate } from 'react-router-dom';

// import { Header } from '../../components/Header';
// import logoSvg from '../../assets/img/vertical-splash.png';

export const ForgotPasswordPage = () => {
	// const navigate = useNavigate();

	const [currentStep, setCurrentStep] = useState<number>(1);

	const [forgotPasswordData, setForgotPasswordData] = useState<ForgotPasswordData>({
		email: '',
		verificationCode: ''
	});

	// Get the child state and Update the current state data
	const handleUpdateData = (updatedData: ForgotPasswordData) => {
		setForgotPasswordData(updatedData);
	};

	// Increment the current step by 1 count
	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	// Decrement the current step by 1 count
	const handlePrevStep = () => {
		setCurrentStep(currentStep - 1);
	};

	// Decide the which component render based on the step
	const getStepComponent = (step: number) => {
		let selectedCard: JSX.Element;
		switch (step) {
			case 1:
				selectedCard = (
					<EmailCheck
						forgotPasswordData={forgotPasswordData}
						onNextStep={handleNextStep}
						onUpdateData={handleUpdateData}
					/>
				);
				break;
			case 2:
				selectedCard = (
					<PasscodeVerification
						forgotPasswordData={forgotPasswordData}
						onNextStep={handleNextStep}
						onPrevStep={handlePrevStep}
						onUpdateData={handleUpdateData}
					/>
				);
				break;
			// case 3:
			// 	selectedCard = (
			// 		<NewPassword
			// 			forgotPasswordData={forgotPasswordData}
			// 			onNextStep={handleNextStep}
			// 			onPrevStep={handlePrevStep}
			// 			onUpdateData={handleUpdateData}
			// 		/>
			// 	);
			// 	break;

			default:
				selectedCard = <div>Test</div>;
		}
		return selectedCard;
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>Reset Password - Incomm Benefits</title>
			</Helmet>
			<div className={classes.container}> {getStepComponent(currentStep)}</div>
		</React.Fragment>
	);
};
