import React, { useState } from 'react';
import classes from './SignupPage.module.scss';
import { StepOne, StepTwo, StepThree, StepFour } from '../../components/Signup';
import { Helmet } from 'react-helmet';
// import { Stepper } from '../../components/common/Stepper';
// import { Step } from '../../components/common/Step';
// import { Header } from '../../components/Header';

export const SignupPage = () => {
	//const [isLoading, setIsLoading] = useState<boolean>(false);

	const [currentStep, setStep] = useState<number>(1);

	const [userData, setUserData] = useState({
		email: '',
		company_name: '',
		first_name: '',
		last_name: '',
		token: '',
		dob: '',
		ssn: '',
		password: '',
		is_fsa: false,
		is_hsa: true,
		is_dcfsa: false,
		user_token: ''
	});

	function handleNextStep() {
		setStep(currentStep + 1);
	}

	function handlePreviousStep() {
		setStep(currentStep - 1);
	}

	function getStepComponent(step: number) {
		let selectedCard: JSX.Element;
		switch (step) {
			case 1:
				selectedCard = (
					<StepOne
						onNextStep={handleNextStep}
						onPreviousStep={handlePreviousStep}
						userData={userData}
						setUserData={setUserData}
					/>
				);
				break;
			case 2:
				selectedCard = (
					<StepTwo
						onNextStep={handleNextStep}
						onPreviousStep={handlePreviousStep}
						userData={userData}
						setUserData={setUserData}
					/>
				);
				break;
			case 3:
				selectedCard = (
					<StepThree
						onNextStep={handleNextStep}
						onPreviousStep={handlePreviousStep}
						userData={userData}
						setUserData={setUserData}
					/>
				);
				break;
			case 4:
				selectedCard = (
					<StepFour
						onNextStep={handleNextStep}
						onPreviousStep={handlePreviousStep}
						userData={userData}
						setUserData={setUserData}
					/>
				);
				break;
			default:
				selectedCard = (
					<StepOne
						onNextStep={handleNextStep}
						onPreviousStep={handlePreviousStep}
						userData={userData}
						setUserData={setUserData}
					/>
				);
		}
		return selectedCard;
	}

	// const steps = ['Connect', 'Verify', 'Setup Password', 'Activate Card'];

	return (
		<React.Fragment>
			{/* <Header /> */}
			<div className={classes.content}>
				<Helmet>
					<title>SignUp - InComm Benefits</title>
				</Helmet>
				{getStepComponent(currentStep)}
			</div>
		</React.Fragment>
	);
};
