// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgBank = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			fillRule="evenodd"
			d="M27.38 11.11h1.561a.776.776 0 0 0 .354-1.469l-13.373-6.89a.79.79 0 0 0-.71 0L1.754 9.641a.78.78 0 0 0-.4.874c.082.35.396.594.754.594H3.75v2.447c0 .427.35.777.776.777h22.076c.427 0 .777-.35.777-.777zm-1.643-1.554H5.33l10.238-5.24zm.093 3.223H5.304v-1.67H25.83zM26.603 28H4.527a.78.78 0 0 1-.776-.777V24c0-.427.35-.777.776-.777h22.076c.427 0 .777.35.777.777v3.223c0 .428-.35.777-.777.777M5.308 26.447H25.83v-1.67H5.304v1.67zm18.467-5.383c0 .427.35.776.777.776s.777-.35.777-.776v-4.976a.78.78 0 0 0-.777-.776.78.78 0 0 0-.777.776zm-3.767.776a.78.78 0 0 1-.777-.776v-4.976c0-.427.35-.776.777-.776s.777.35.777.776v4.976c0 .427-.35.776-.777.776m-5.32-.776c0 .427.349.776.776.776s.777-.35.777-.776v-4.976a.78.78 0 0 0-.777-.776.78.78 0 0 0-.777.776zm-3.764.776a.78.78 0 0 1-.777-.776v-4.976a.78.78 0 0 1 .777-.776.78.78 0 0 1 .777.776v4.976c0 .427-.35.776-.777.776m-5.321-.776c0 .427.35.776.777.776s.776-.35.776-.776v-4.976a.78.78 0 0 0-.776-.776.78.78 0 0 0-.777.776z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgBank;
