import { getDecimalVal } from './numberUtil';
import { dateUtil } from './dateUtil';
import { ITransaction } from '../interface/common.interface';

export const groupTransaction = (tData: any, achTransactions: any = []) => {
	const transactionObj: any = {};
	const transactions: Array<{ sectionName: string; data: Array<ITransaction> }> = [];
	tData.forEach((data: ITransaction) => {
		// const groupName: string =
		// 	data.expense_mode && data.expense_mode == 'manual'
		// 		? moment.tz(data.date * 1000, 'UTC').format('MMMM YYYY')
		// 		: dateUtil.getFormattedDate(new Date(data.date * 1000), 'MMMM YYYY');
		const groupName: string = dateUtil.getFormattedDate(
			new Date((data.date as number) * 1000),
			'MMMM YYYY'
		);
		if (transactionObj[groupName]) {
			transactionObj[groupName].push(data);
		} else {
			transactionObj[groupName] = [data];
		}
	});
	Object.keys(transactionObj).forEach((key: string) => {
		transactions.push({ sectionName: key, data: transactionObj[key] });
	});
	if (achTransactions.length > 0) {
		const pendingData = [
			...achTransactions.map((achTransaction: any) => {
				return {
					date: achTransaction.date,
					activity: achTransaction.indicator == 'D' ? 'Deposit' : 'Credit',
					description: '',
					//  achTransaction.indicator == 'D' ? 'To Everyday' : 'From Everyday',
					amount: achTransaction.txn_amount
				};
			})
		];
		transactions.unshift({ sectionName: 'Pending Transactions', data: pendingData });
	}

	return [
		...transactions.map((transaction: { sectionName: string; data: Array<ITransaction> }) => {
			transaction.data = transaction.data.map((transactionCol: ITransaction) => {
				// transactionCol.date =
				// 	transactionCol.expense_mode && transactionCol.expense_mode == 'manual'
				// 		? moment.tz(transactionCol.date * 1000, 'UTC').format('MMM DD')
				// 		: dateUtil.getFormattedDate(new Date(transactionCol.date * 1000), 'MMM DD');

				transactionCol.date = dateUtil.getFormattedDate(
					new Date((transactionCol.date as number) * 1000),
					'MMM DD'
				);

				transactionCol.amount = `${
					transactionCol.mode == 'debit'
						? !transactionCol.description.includes('Investments')
							? '-'
							: ''
						: ''
				}$${getDecimalVal(transactionCol.amount as number)}`;
				return transactionCol;
			});
			return transaction;
		})
	];
};
