// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgHome = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			fillRule="evenodd"
			d="M23.215 11.306h-1.473v10.299c0 .428-.35.779-.78.779h-18.1a.78.78 0 0 1-.78-.78V11.307h-1.3A.779.779 0 0 1 .033 10.3l2.34-7.747A.78.78 0 0 1 3.125 2H20.88c.343 0 .647.226.748.554l2.338 7.746a.78.78 0 0 1-.748 1.006zm-19.574 0h16.542v9.52h-4.781v-6.064c0-.429-.35-.78-.78-.78H9.375c-.43 0-.78.351-.78.78v6.063H3.641zM1.833 9.747h20.334L20.3 3.559H3.7zm12.01 11.078h-3.69v-5.284h3.69z"
			clipRule="evenodd"
		/>
	</svg>
);
export default SvgHome;
