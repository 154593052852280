import React from 'react';
import { StyledLinkSizes, StyledLinkTypes } from './StyledLink.constants';
import classes from './StyledLink.module.scss';
import { getClassNames } from '../../../utils/Css';

type StyledLinkProps = {
	href?: string;
	isOpenInNewTab?: boolean;
	children: React.ReactNode | string;
	size?: StyledLinkSizes;
	isDisabled?: boolean;
	hideUnderLine?: boolean;
	type?: StyledLinkTypes;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const StyledLink = ({
	children,
	size = StyledLinkSizes.MD,
	type = StyledLinkTypes.PRIMARY,
	isOpenInNewTab = true,
	isDisabled = false,
	hideUnderLine = false,
	...restProps
}: StyledLinkProps) => {
	// To get the css class for specific to size
	const getLinkSize = (linkSize: string) => {
		let cssClass = '';
		switch (linkSize) {
			case StyledLinkSizes.LG:
				cssClass = classes['link-large'];
				break;
			case StyledLinkSizes.MD:
				cssClass = classes['link-medium'];
				break;

			case StyledLinkSizes.SM:
				cssClass = classes['link-small'];
				break;
			default:
				break;
		}
		return cssClass;
	};

	const getClassByStyleLinkType = (linkType: StyledLinkTypes) => {
		let cssClass = '';
		switch (linkType) {
			case StyledLinkTypes.PRIMARY:
				cssClass = classes['link-primary'];
				break;
			case StyledLinkTypes.SECONDARY:
				cssClass = classes['link-secondary'];
				break;
			case StyledLinkTypes.TERTIARY:
				cssClass = classes['link-tertiary'];
				break;
			default:
				break;
		}
		return cssClass;
	};

	const hideUnderLineFromLink = () => {
		if (hideUnderLine) {
			return classes['hide-underline'];
		} else {
			return classes['show-underline'];
		}
	};

	const classNames = getClassNames(
		classes['link-basic'],
		isDisabled && classes.disabled,
		getLinkSize(size),
		hideUnderLineFromLink(),
		getClassByStyleLinkType(type)
	);

	return (
		<a
			tabIndex={0}
			role={restProps.href ? 'link' : restProps.onClick ? 'button' : ''}
			onKeyDown={e => {
				if (e.key == 'Enter' || e.key == ' ') {
					e.preventDefault();
					e.currentTarget.click();
				}
			}}
			className={classNames}
			target={isOpenInNewTab ? '_blank' : '_self'}
			rel="noopener noreferrer"
			{...restProps}
		>
			{children}
		</a>
	);
};
