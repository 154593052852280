import React from 'react';

import classes from './Checkbox.module.scss';
import { getClassNames } from '../../../utils/Css';

type CheckboxProps = {
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked: boolean;
	isDisabled?: boolean;
	children?: React.ReactNode;
};
export const Checkbox = ({ checked, isDisabled = false, children, onChange }: CheckboxProps) => {
	const classNames = getClassNames(
		classes.box,
		checked && classes.checked,
		isDisabled && classes.disabled
	);

	const classNamesForLabel = getClassNames(
		classes.label,
		isDisabled && classes.disabled,
		'is-size-h4-r',
		'd-flex',
		'align-items-center'
	);

	return (
		<label className={classNamesForLabel}>
			<div>
				<span className={classNames}></span>
				<input
					type="checkbox"
					disabled={isDisabled}
					checked={checked}
					onChange={onChange}
					className={classes['input-checkbox']}
				/>
			</div>
			<div>{children}</div>
		</label>
	);
};
