/* eslint-disable max-lines */
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import classes from './StepTwo.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { FormControl } from '../../common/FormControl';
// import { Input, InputTypes } from '../../common/Input';
import ReactInputVerificationCode from 'react-input-verification-code';
import { StyledLink } from '../../common/Link';
import { dateUtil } from '../../../utils/dateUtil';
import { signupApi } from '../../../services/signupApi';

import { Banner, BannerTypes } from '../../../components/common/Banner';
import Warning from '../../../components/icons/Warning';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { UserData } from '../../../types/signup.types';
import verifyIdSvg from '../../../assets/img/verifyId.svg';
import { StyledLinkTypes } from '../../common/Link/StyledLink.constants';
import { ControlType } from '../../common/FormControl/FormControl.constants';

type StepTwoFormValues = {
	passcode: string;
};

type StepProps = {
	onNextStep: () => void;
	onPreviousStep: () => void;
	userData: UserData;
	setUserData: (data: UserData) => void;
};

export const StepTwo = ({ onNextStep, onPreviousStep, userData, setUserData }: StepProps) => {
	// form with default values
	const { handleSubmit, control, formState, reset } = useForm<StepTwoFormValues>({
		defaultValues: {
			passcode: ''
		},
		mode: 'onChange'
	});
	// state to show error message if api fails
	const [signupError, setSignupError] = useState({ code: '', message: '' });

	const [resendDisable, setResendDisable] = useState<boolean>(false);

	// useEffect(() => {
	// 	(window as any).gtag('event', 'page_view', {
	// 		app_name: 'Zenda-web',
	// 		page_title: 'Onboarding_work_email_confirm',
	// 		// screen_name: 'Onboarding_work_email_confirm',
	// 		send_to: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || ''
	// 	});
	// }, []);

	const onSubmit = handleSubmit((data: StepTwoFormValues) => {
		setSignupError({ code: '', message: '' });
		const postData = {
			...data,
			email: userData.email?.toLowerCase().trim(),
			sent_at: dateUtil.getEpochTime()
		};

		//varify passcode that has been received via email
		signupApi
			.passCodeVerify(postData)
			.then((response: any) => {
				userData.first_name = response.data.first_name;
				userData.last_name = response.data.last_name;
				userData.token = response.data.token;
				userData.is_fsa = response.data?.is_fsa || false;
				userData.is_hsa = response.data?.is_hsa || false;
				userData.is_dcfsa = response.data?.is_dcfsa || false;
				userData.user_token = response.data?.user_token;
				localStorageUtil.setPreSignupAuth(response.data.token);
				setUserData(userData);
				onNextStep();
			})
			.catch((error: any) => {
				reset();
				setSignupError(error.response.data.error);
			});
	});

	/**
	 * Call the Register API to fetch the verification code
	 */
	const getPassCode = () => {
		setResendDisable(true);
		setTimeout(() => {
			setResendDisable(false);
		}, 20 * 1000);
		const postData = {
			email: userData.email?.toLowerCase().trim(),
			dob: dateUtil.getFormattedDate(userData.dob),
			ssn: userData.ssn,
			sent_at: dateUtil.getEpochTime()
		};
		signupApi
			.preSignup(postData)
			.then(() => {})
			.catch((error: any) => {
				// set error to show banner
				setSignupError(error.response.data.error);
			});
	};

	return (
		<div className="pt-32">
			<div className={classes.header}>
				<img src={verifyIdSvg} width="200px"></img>
				<p className={` text-h2-325 pt-48 ${classes.title}`}>Verify your identity.</p>
			</div>
			<div className={classes.card}>
				{signupError && signupError.message && (
					<span className={`${classes.banner} pt-32`}>
						<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
							{signupError.message}
						</Banner>
					</span>
				)}

				<form autoComplete="off" onSubmit={onSubmit} className={classes.form}>
					<div className="mb-48 d-flex flex-column">
						<span className={`pb-8 ${classes.info}`}>
							We found your account with {userData.company_name}.
						</span>
						<span className={classes.info}> We sent a security code to:</span>
						<span className={classes.info}> {userData.email?.toLowerCase().trim()}</span>
					</div>

					<div>
						<Controller
							control={control}
							name="passcode"
							rules={{
								required: 'Passcode is required.',
								minLength: { value: 6, message: 'Please enter 6 digit Passcode.' }
							}}
							render={({ field: { onChange, value }, fieldState: {} }) => (
								<>
									<FormControl
										controlType={ControlType.LIGHT}
										label={() => 'Your 6 Digit Code'}
										caption={() => 'Make sure to check your spam folder'}
									>
										<ReactInputVerificationCode
											value={value}
											length={6}
											placeholder={''}
											onChange={onChange}
											autoFocus={true}
										/>
									</FormControl>
								</>
							)}
						/>
					</div>

					<div className={'d-flex flex-column gap-20 mt-32'}>
						<Button
							onClick={(e: any) => e.target?.blur()}
							isDisabled={!formState.isValid}
							type={ButtonTypes.TERTIARY}
						>
							Verify
						</Button>
						<Button
							onClick={(e: any) => {
								e.preventDefault();
								onPreviousStep();
							}}
							type={ButtonTypes.PRIMARY}
						>
							Back
						</Button>
					</div>
				</form>
				<span className={`text-base-325 p-16 mt-16`}>
					<span className={classes['resend-text']}>Didn’t get the code?</span>
					<StyledLink
						isDisabled={resendDisable}
						onClick={getPassCode}
						type={StyledLinkTypes.TERTIARY}
					>
						Resend
					</StyledLink>
				</span>
			</div>
		</div>
	);
};
