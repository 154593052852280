// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgCard = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 24 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill="#2A518C"
			d="M18.4 5.543H5.6a1.61 1.61 0 0 0-1.6 1.6v9.143a1.61 1.61 0 0 0 1.6 1.6h12.8a1.61 1.61 0 0 0 1.6-1.6V7.143a1.61 1.61 0 0 0-1.6-1.6M5.6 6.914h12.8a.23.23 0 0 1 .229.229V9.2H5.37V7.143a.23.23 0 0 1 .229-.229m12.8 9.6H5.6a.23.23 0 0 1-.229-.228V10.57H18.63v5.715a.23.23 0 0 1-.229.228"
		/>
		<path
			fill="#2A518C"
			d="M9.258 12.629h-1.83a.914.914 0 1 0 0 1.829h1.83a.914.914 0 1 0 0-1.83"
		/>
	</svg>
);
export default SvgCard;
