/* eslint-disable max-lines */
import React, { useState, useRef, useEffect } from 'react';
import classes from './ExpenseEditDocumentPage.module.scss';
import { ChevronClose, Warning } from '../../components/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, ButtonTypes } from '../../components/common/Button';
import { Banner, BannerTypes } from '../../components/common/Banner';
import { Loader } from '../../components/common/Loader';
import uploadIcon from '../../assets/img/uploadIcon.svg';
import successImage from '../../assets/img/ThumbsUpLight.svg';
import { StyledLink, StyledLinkSizes } from '../../components/common/Link';
import pdfImage from '../../assets/img/pdf.png';
import { expenseApi } from '../../services/expenseService';
import axios from 'axios';
import { DocumentUploadModule, ExpenseStatusType, UploadScanStatus } from '../../constants/common';
import { dateUtil } from '../../utils/dateUtil';
import { fileUtils } from '../../utils/fileUtil';
import { useDispatch } from 'react-redux';
import { changeBackground } from '../../store/backgroundSlice';
import { Oval } from 'react-loader-spinner';

type FileDetails = {
	id: number;
	name: string;
	image: File;
	base64?: string;
	scanPercent: number;
	file_id?: string;
	status: UploadScanStatus;
};

export const ExpenseEditDocumentPage = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [uploadImageList, setUploadImageList] = useState<Array<FileDetails>>([]);
	const scanIntervalRef = useRef<any>(null);
	const uploadedDocumentRef = useRef<Array<FileDetails>>([]);
	const submittedRef = useRef<boolean>(false);
	const [customError, setCustomError] = useState<{ message: string }>({ message: '' });
	const [numberOfDocumentProcessing, setNumberOfDocumentProcessing] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [documentList, setDocumentList] = useState<Array<string>>([]);

	const [uploadedList, setUploadedList] = useState<Array<FileDetails>>([]);

	const [submitted, setSubmitted] = useState<boolean>(false);
	const fileInputRef = useRef<any>(null);
	const expenseStatus = searchParams.get('status');
	const [expenseDetails, setExpenseDetails] = useState<any>({});
	const [isScanning, setIsScanning] = useState<boolean>(false);

	const dispatch = useDispatch<any>();

	useEffect(() => {
		const getExpenseDetails = (expenseId: string) => {
			setIsLoading(true);
			expenseApi
				.getExpense(expenseId)
				.then((response: any) => {
					setIsLoading(false);
					setExpenseDetails(response.data);
				})
				.catch(() => {
					navigate('/apps/expenses/summary');
				});
		};
		const expenseId = searchParams.get('id');
		if (!expenseId) {
			navigate('/apps/expenses/summary');
			return;
		}
		getExpenseDetails(expenseId);

		return () => {
			dispatch(changeBackground(false));
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [dispatch]);

	useEffect(() => {
		uploadedDocumentRef.current = uploadedList;
	}, [uploadedList]);

	useEffect(() => {
		submittedRef.current = submitted;
	}, [submitted]);

	const uploadToS3 = (file: any, signedUrl: string) => {
		const options = {
			headers: {
				'Content-Type': file.type
			}
		};
		return axios.put(signedUrl, file, options);
	};

	const reSubmitAttachment = () => {
		expenseApi
			.reSubmit(searchParams.get('id') as string)
			.then(() => {
				setSubmitted(true);
				setIsLoading(false);
			})
			.catch();
	};

	const deleteAllUnSubmittedAttachment = () => {
		const filesToDelete: Array<string> = [];
		const expenseId = searchParams.get('id');
		uploadedDocumentRef.current.forEach(file => {
			if (
				file.status == UploadScanStatus.FILE_ACCEPTED ||
				file.status == UploadScanStatus.IN_PROGRESS
			) {
				filesToDelete.push(`${file.file_id}_${file.name}`);
			}
		});
		if (filesToDelete.length) {
			expenseApi
				.deleteExpenseAttachment(expenseId as string, {
					delete_file_list: filesToDelete
				})
				.then()
				.catch();
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		return () => {
			if (scanIntervalRef.current) {
				clearInterval(scanIntervalRef.current);
			}
			if (!submittedRef.current) {
				deleteAllUnSubmittedAttachment();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fixAsserted = () => {
		expenseApi
			.fixAssert(searchParams.get('id') as string, {
				assert_with_document: true,
				sent_at: dateUtil.getEpochTime()
			})
			.then(() => {
				setSubmitted(true);
				setIsLoading(false);
			})
			.catch();
	};

	const submitDocument = () => {
		setIsLoading(true);
		if (
			expenseStatus == ExpenseStatusType.NEEDS_MORE_INFO ||
			expenseStatus == ExpenseStatusType.ELIGIBLE_FOR_REIMBURSEMENT ||
			expenseStatus == ExpenseStatusType.SETTLED ||
			expenseStatus == ExpenseStatusType.DENIED ||
			expenseStatus == ExpenseStatusType.PARTIALLY_APPROVED
		) {
			reSubmitAttachment();
		} else if (expenseStatus == ExpenseStatusType.RECEIPT_RECOMMENDED) {
			fixAsserted();
		} else {
			setSubmitted(true);
			setIsLoading(false);
		}
	};

	const uploadDocument = async () => {
		const expenseFiles: Array<string> = [];
		uploadImageList.map(imageData => {
			expenseFiles.push(imageData.name);
		});
		if (!uploadImageList.length) {
			return;
		}

		// setIsLoading(true);
		setIsScanning(true);
		const fileUploadDetail = {
			service: 'consumer',
			module: DocumentUploadModule.EXPENSE,
			payload: {
				expense_id: expenseDetails.expense_id
			},
			file_names: expenseFiles
		};

		expenseApi
			.fileUpload(fileUploadDetail)
			.then(response => {
				let apiResponseSignedUrls: Array<{ url: string; file_name: string; file_id: string }> =
					response.data.presigned_url;
				const uploadedFiles: any = [];

				let attachmentsFromExpense: any = [...uploadedList, ...uploadImageList];

				// Update the file_id based on the api response
				apiResponseSignedUrls.forEach(apiResponse => {
					const foundFile = attachmentsFromExpense.find(
						(tmpFile: any) => tmpFile.name == apiResponse.file_name
					);
					if (foundFile) {
						foundFile.file_id = apiResponse.file_id;
					}
				});
				setUploadedList(attachmentsFromExpense);
				setUploadImageList([]);

				apiResponseSignedUrls.forEach(apiResponse => {
					const file = uploadImageList.find(image => image.name == apiResponse.file_name);
					if (file) {
						if (file.name == apiResponse.file_name) {
							uploadedFiles.push(uploadToS3(file.image, apiResponse.url));
						}
					}
				});

				Promise.all(uploadedFiles).then(() => {
					scanIntervalRef.current = setInterval(() => {
						const scanQueue: any = [];

						if (!apiResponseSignedUrls.length) {
							clearInterval(scanIntervalRef.current);
							setIsScanning(false);
							return;
						}
						apiResponseSignedUrls.forEach(signedUrl => {
							scanQueue.push(expenseApi.getScannedFile(signedUrl.file_id));
						});

						Promise.all(scanQueue)
							.then(scannedResult => {
								const scannedFiles = scannedResult.map(result => result.data.data);
								attachmentsFromExpense = attachmentsFromExpense.map((attachment: any) => {
									const scanFileResult = scannedFiles.find(
										scannedFile => scannedFile.file_name == attachment.name
									);

									if (scanFileResult) {
										attachment.scanPercent = scanFileResult.progress_percentage || 0;
										attachment.status = scanFileResult.status;

										if (
											[
												UploadScanStatus.FILE_ACCEPTED,
												UploadScanStatus.FILE_REJECTED,
												UploadScanStatus.ERRORED_OUT
											].includes(scanFileResult.status)
										) {
											apiResponseSignedUrls = apiResponseSignedUrls.filter(
												signedUrl => !(signedUrl.file_name == attachment.name)
											);
										}
									}
									return attachment;
								});
								setUploadedList(attachmentsFromExpense);
							})
							.then(console.log);
					}, 1000);
				});
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const validTypesType = async (files: any) => {
		for (let i = 0; i < files.length; i = i + 1) {
			try {
				await fileUtils.validateFileType(files[i]);
			} catch (err: any) {
				return false;
			}
		}
		return true;
	};

	const validNumberOfFile = (files: any, validNumber = 10) => {
		const numberOfDocumentInExpense = expenseDetails?.documents;
		validNumber = validNumber - numberOfDocumentInExpense.length;
		return [...uploadImageList, ...files].length > validNumber ? false : true;
	};
	// const appendTextToName = (name: string, text: string, options?: { ext: string }) => {
	// 	const compressFileNameArray = name.split('.');
	// 	let updatedName = '';
	// 	if (compressFileNameArray.length == 1) {
	// 		updatedName = name + text;
	// 	} else {
	// 		const ext = compressFileNameArray.pop();
	// 		updatedName = compressFileNameArray.join('.') + text + '.' + (options?.ext || ext);
	// 	}
	// 	return updatedName;
	// };
	const getFileNames = (fileName: string) => {
		const fileNameArray = fileName.split('.');
		const ext = fileNameArray.pop();
		let actualFileName = fileNameArray.join('.');

		const documentWithSameName = documentList.filter(documentName =>
			documentName.toLowerCase().includes(actualFileName.toLowerCase())
		);

		if (documentWithSameName?.length) {
			actualFileName = `${actualFileName}(${documentWithSameName.length})`;
		}

		setDocumentList([...documentList, `${actualFileName}.${ext}`]);

		return `${actualFileName}.${ext}`;
	};

	const handelInputFiles = async (files: any) => {
		if (!fileUtils.validFilesSize(files)) {
			setCustomError({ message: 'Maximum 10 MB document size allowed.' });
			return;
		}
		if (!(await validTypesType(files))) {
			setCustomError({
				message: 'Invalid document format, Allowed formats are jpeg, jpg, png, pdf.'
			});
			return;
		}
		if (!validNumberOfFile(files)) {
			setCustomError({ message: 'Maximum 10 documents allowed.' });
			return;
		}
		if (!fileUtils.validateFileName(files)) {
			setCustomError({ message: 'Invalid file name' });
			return;
		}
		setCustomError({ message: '' });
		for (let i = 0; i < files.length; i = i + 1) {
			try {
				if (files[i].type.startsWith('image/')) {
					setNumberOfDocumentProcessing(previousValue => previousValue + 1);
					const reader = new FileReader();
					reader.addEventListener(
						'load',
						async () => {
							const fileName = getFileNames(fileUtils.sanitizeFileName(files[i].name));
							setUploadImageList(prevState => [
								...prevState,
								{
									id: uploadImageList.length,
									name: fileName,
									image: files[i],
									file_type: files[i].type.split('/').pop(),
									//compressedFile,
									base64: reader.result as string,
									scanPercent: 0,
									status: UploadScanStatus.IN_PROGRESS
								}
							]);
							setNumberOfDocumentProcessing(previousValue => previousValue - 1);
						},
						false
					);
					reader.readAsDataURL(files[i]);
				} else if (files[i].type == 'application/pdf') {
					const fileName = getFileNames(fileUtils.sanitizeFileName(files[i].name));
					setUploadImageList(prevState => [
						...prevState,
						{
							id: uploadImageList.length,
							name: fileName,
							image: files[i],
							file_type: files[i].type.split('/').pop(),
							base64: '',
							scanPercent: 0,
							status: UploadScanStatus.IN_PROGRESS
						}
					]);
				}
			} catch (error) {
				console.log(error);
			}
		}
	};
	// const remove = (fileObject: any) => {
	// 	setUploadImageList(uploadImageList.filter(image => image.name != fileObject.name));
	// };

	const removeUploaded = (file: any) => {
		if (
			[UploadScanStatus.ERRORED_OUT, UploadScanStatus.FILE_REJECTED].includes(
				file.status as UploadScanStatus
			)
		) {
			const remainingFiles = uploadedList.filter(attachment => attachment.name != file.name);
			setUploadedList(remainingFiles);
		} else {
			setIsLoading(true);
			const expenseId = searchParams.get('id');
			expenseApi
				.deleteExpenseAttachment(expenseId as string, {
					delete_file_list: [`${file.file_id}_${file.name}`]
				})
				.then(() => {
					const remainingFiles = uploadedList.filter(attachment => attachment.name != file.name);
					setUploadedList(remainingFiles);
					setIsLoading(false);
				})
				.catch(error => {
					console.log('Error', error);
					if (error.response.status == 400) {
						setCustomError({
							message: error.response.data.error.message || 'Something went wrong.'
						});
					}
					setIsLoading(false);
				});
		}
	};

	useEffect(() => {
		console.log('checking for upload');
		if (uploadImageList.length && !numberOfDocumentProcessing) {
			uploadDocument();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadImageList, numberOfDocumentProcessing]);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<div className="mt-64">
				<div className="d-flex align-items-center">
					{submitted ? (
						<></>
					) : (
						<div
							className={`d-flex align-items-center ${classes['close-icon']}  px-4`}
							onClick={() => {
								navigate(`/apps/expense?id=${searchParams.get('id')}`);
							}}
						>
							<ChevronClose color="#eff3fa" />
						</div>
					)}
					<div
						className={`d-flex justify-content-center align-items-center w-100 ${classes['expense-header']}`}
					>
						<p className={classes['header-text']}>Attach Documents</p>
					</div>
				</div>
				{submitted ? (
					<>
						<div className="d-flex flex-column align-items-center mt-128">
							<img
								className={`${classes['success-image']} py-16`}
								width={236}
								height={236}
								src={successImage}
							/>
							<p className={`${classes['success-message']} pt-48 text-center`}>
								Submission Complete!
							</p>

							{expenseStatus == ExpenseStatusType.NEEDS_MORE_INFO && (
								<>
									<p className="text-base-325 py-16 text-primary">
										<span className="text-base-700">Please allow 4-5 business days</span> for us to
										review your expense and process your reimbursement.
									</p>
									<p className="text-sm-325 text-primary">
										We will notify you once the expense is approved or if we need additional
										information.
									</p>
									<hr />
								</>
							)}
							<div className={`${classes['md-reverse']} row w-100  justify-content-center pt-48`}>
								<div className=" d-flex flex-column cl-lg-4  cl-md-12 pt-24">
									<Button
										type={ButtonTypes.SECONDARY}
										onClick={() => {
											navigate('/apps/expenses/summary');
										}}
									>
										Return to Dashboard
									</Button>
								</div>
								<div className=" d-flex flex-column cl-lg-4 cl-md-12 pt-24">
									<Button
										type={ButtonTypes.PRIMARY}
										onClick={e => {
											e.preventDefault();
											navigate(`/apps/expense?id=${searchParams.get('id')}&isClearRoute=true`);
										}}
									>
										View Expense
									</Button>
								</div>
							</div>
						</div>
					</>
				) : (
					<>
						<p className={`text-h5-700 mt-128 text-primary`}>Upload your expense documents</p>
						<p className="text-base-325 py-12 text-primary">
							Attach proof documents to your expense as part of your submission.
						</p>

						<div className="py-12">
							<p className="text-base-325 text-primary">
								Please ensure your document(s) has the following:
							</p>
							<ul>
								<li className="text-base-325 text-primary">Date of service</li>
								<li className="text-base-325 text-primary">Provider or merchant name</li>
								<li className="text-base-325 text-primary">
									Details of the service or product received and the amount charged.
								</li>
							</ul>
							<p className="text-base-325 text-primary">
								Itemized receipts, bills or an explanation of benefits (EOB) document are all
								sufficient proof documents.
							</p>

							<p className={'text-h5-325 text-primary mt-32 mb-12'}>Documents</p>

							<hr className="m-0" />

							{numberOfDocumentProcessing ? <Loader /> : ''}

							{uploadedList && uploadedList.length && !isLoading
								? uploadedList.map((section, index) => {
										return (
											<React.Fragment key={`upload-file-list-${index}`}>
												<div
													key={`uploaded-file-list-${index}`}
													className={`d-flex justify-content-between align-items-center py-24 px-12 ${
														[UploadScanStatus.FILE_REJECTED, UploadScanStatus.ERRORED_OUT].includes(
															section.status as UploadScanStatus
														)
															? classes['error-document']
															: null
													}`}
													onDrop={(e: any) => {
														handelInputFiles(e.dataTransfer.files);
														e.preventDefault();
													}}
													onDragOver={e => {
														e.preventDefault();
													}}
												>
													<div className="d-flex align-items-center">
														<img
															onClick={() => {
																if (section.base64) {
																	const image = new Image();
																	image.src = section.base64 as string;
																	const w = window.open('');
																	w?.document.write(image.outerHTML);
																} else {
																	const blob = new Blob([section.image], {
																		type: section.image.type
																	});
																	const fileURL = window.URL.createObjectURL(blob);
																	window.open(fileURL, '_blank');
																	window.URL.revokeObjectURL(fileURL);
																}
															}}
															className={classes['image-position']}
															src={section.base64 || pdfImage}
															width={'50'}
															height={'50'}
														/>
														<p className="text-base-325 px-12 text-primary">{section.name || ''}</p>
													</div>
													<div>
														{section.status == UploadScanStatus.IN_PROGRESS ||
														section.status == null ? (
															<div className="d-flex justify-content-center align-items-center gap-20">
																<Oval
																	height={45}
																	width={45}
																	color="#296db2"
																	wrapperStyle={{
																		maxHeight: '45px',
																		maxWidth: '45px',
																		alignItems: 'center',
																		justifyContent: 'center'
																	}}
																	wrapperClass=""
																	visible={true}
																	ariaLabel="oval-loading"
																	secondaryColor="#fafcfe"
																	strokeWidth={4}
																	strokeWidthSecondary={4}
																/>
																{section.scanPercent}%
															</div>
														) : null}
													</div>
													{[
														UploadScanStatus.FILE_ACCEPTED,
														UploadScanStatus.ERRORED_OUT,
														UploadScanStatus.FILE_REJECTED
													].includes(section.status) ? (
														<div>
															<StyledLink
																size={StyledLinkSizes.SM}
																onClick={() => {
																	removeUploaded(section);
																}}
															>
																Remove
															</StyledLink>
														</div>
													) : null}
												</div>
												<hr className="my-0" />
											</React.Fragment>
										);
								  })
								: ''}

							{uploadImageList && uploadImageList.length
								? uploadImageList.map((section, index) => {
										return (
											<React.Fragment key={`upload-file-list-${index}`}>
												<div
													key={`uploaded-file-list-${index}`}
													className={`d-flex justify-content-between align-items-center py-24 px-12`}
													onDrop={(e: any) => {
														handelInputFiles(e.dataTransfer.files);
														e.preventDefault();
													}}
													onDragOver={e => {
														e.preventDefault();
													}}
												>
													<div className="d-flex align-items-center">
														<img
															onClick={() => {
																if (section.base64) {
																	const image = new Image();
																	image.src = section.base64 as string;
																	const w = window.open('');
																	w?.document.write(image.outerHTML);
																} else {
																	const blob = new Blob([section.image], {
																		type: section.image.type
																	});
																	const fileURL = window.URL.createObjectURL(blob);
																	window.open(fileURL, '_blank');
																	window.URL.revokeObjectURL(fileURL);
																}
															}}
															className={classes['image-position']}
															src={section.base64 || pdfImage}
															width={'50'}
															height={'50'}
														/>

														<p className="text-body px-12">{section.name || ''}</p>
													</div>
													<div>
														<div className="d-flex justify-content-center align-items-center gap-20">
															<Oval
																height={45}
																width={45}
																color="#296db2"
																wrapperStyle={{
																	maxHeight: '45px',
																	maxWidth: '45px',
																	alignItems: 'center',
																	justifyContent: 'center'
																}}
																wrapperClass=""
																visible={true}
																ariaLabel="oval-loading"
																secondaryColor="#fafcfe"
																strokeWidth={4}
																strokeWidthSecondary={4}
															/>
															{section.scanPercent}%
														</div>
													</div>
												</div>
												<hr className="my-0" />
											</React.Fragment>
										);
								  })
								: ''}

							{uploadedList.length || uploadImageList.length ? null : (
								<div
									className="d-flex justify-content-center py-32"
									onClick={() => {
										fileInputRef?.current?.click();
									}}
									onDrop={(e: any) => {
										handelInputFiles(e.dataTransfer.files);
										e.preventDefault();
									}}
									onDragOver={e => {
										e.preventDefault();
									}}
								>
									<img src={uploadIcon} width={75} height={75} />
								</div>
							)}

							{/* {uploadImageList?.length == 0 ? <hr className="my-24" /> : ''} */}
							{numberOfDocumentProcessing ? (
								''
							) : (
								<div
									className="d-flex justify-content-center py-24"
									onDrop={(e: any) => {
										handelInputFiles(e.dataTransfer.files);
										e.preventDefault();
									}}
									onDragOver={e => {
										e.preventDefault();
									}}
								>
									<input
										ref={fileInputRef}
										className={classes['input-file']}
										accept="image/*,application/pdf"
										multiple
										onClick={(event: any) => {
											event.target.value = '';
										}}
										onChange={e => {
											handelInputFiles(e.target.files);
										}}
										type="file"
									></input>
									<StyledLink
										onClick={() => {
											fileInputRef?.current?.click();
										}}
										isDisabled={isScanning}
									>
										<span className="text-base-350">Add a Document</span>
									</StyledLink>
								</div>
							)}
							{customError && customError.message && (
								<>
									<br />
									<span className={classes.banner}>
										<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
											<div className="d-flex align-items-center">{customError.message}</div>
										</Banner>
									</span>
								</>
							)}
							{uploadedList.some(attachment =>
								[UploadScanStatus.FILE_REJECTED].includes(attachment.status as UploadScanStatus)
							) && (
								<>
									<br />
									<span className={classes.banner}>
										<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
											<div className="d-flex align-items-center">
												<b>Upload Rejected!&nbsp; </b> Please try uploading another file.
											</div>
										</Banner>
									</span>
								</>
							)}
							{uploadedList.some(attachment =>
								[UploadScanStatus.ERRORED_OUT].includes(attachment.status as UploadScanStatus)
							) && (
								<>
									<br />
									<span className={classes.banner}>
										<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
											<div className="d-flex align-items-center">
												<b>Upload Error!&nbsp;</b> Please try uploading again.
											</div>
										</Banner>
									</span>
								</>
							)}
							<hr className="my-0" />

							<div className={`row ${classes['md-reverse']}`}>
								<div className=" d-flex flex-column cl-lg-6 pt-24">
									<Button
										onClick={e => {
											e.preventDefault();
											navigate(`/apps/expense?id=${searchParams.get('id')}`);
										}}
										type={ButtonTypes.SECONDARY}
										isDisabled={isScanning}
									>
										Cancel
									</Button>
								</div>
								<div className=" d-flex flex-column cl-lg-6 pt-24">
									<Button
										isDisabled={
											!uploadedList.length ||
											!!numberOfDocumentProcessing ||
											isScanning ||
											uploadedList.some(attachment =>
												[UploadScanStatus.FILE_REJECTED, UploadScanStatus.ERRORED_OUT].includes(
													attachment.status as UploadScanStatus
												)
											)
										}
										onClick={submitDocument}
									>
										Submit Documents
									</Button>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};
