/* eslint-disable max-lines */
import React, { useState } from 'react';
import classes from './CancelExpense.module.scss';

import { Button, ButtonTypes } from '../../common/Button';
import { Loader } from '../../../components/common/Loader';
import Modal from 'react-modal';
import { Incomplete } from '../../icons';

import { expenseApi } from '../../../services/expenseService';
import { getDecimalVal } from '../../../utils/numberUtil';
import { AccountType } from '../../../constants/common';

type CancelExpenseProps = {
	isOpen: boolean;
	expenseId: string;
	expenseDetails: any;
	onSuccess: () => void;
	onCancel: () => void;
};

export const CancelExpense = ({
	isOpen,
	expenseId,
	onSuccess,
	onCancel,
	expenseDetails
}: CancelExpenseProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '700px',
			transform: 'translate(-50%, -50%)',
			padding: '56px 24px',
			backgroundColor: '#001b47',
			borderColor: '#001b47'
		}
	};

	/**
	 * Calculate total amount with message(Exclude HSA account)
	 */
	const getTotalHoldMsg = () => {
		let holdString = '';
		let holdAmount = 0;
		if (expenseDetails?.account_breakdown?.has_reimbursement) {
			const nonHSAAccounts =
				expenseDetails?.account_breakdown?.reimbursement_details?.reimbursement_from_accounts.filter(
					(acc: any) => acc.account.toLowerCase() != AccountType.HSA
				);

			if (nonHSAAccounts.length > 0) {
				holdAmount = nonHSAAccounts.reduce((accumulator: number, obj: any) => {
					return accumulator + obj.amount;
				}, 0);
			}
			holdString = `The hold of ${getDecimalVal(holdAmount)} will be added back to your
		${nonHSAAccounts.map((acc: any) => acc.account).join(', ')}. You can not undo this action.`;
		}
		return holdString;
	};

	const cancelExpense = () => {
		if (!expenseId) {
			return;
		}
		setIsLoading(true);
		expenseApi
			.cancelExpense(expenseId)
			.then(() => {
				setIsLoading(false);
				onSuccess();
			})
			.catch(() => {
				setIsLoading(false);
				onCancel();
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			style={customStyles}
			contentLabel="cancel Expense"
			shouldCloseOnEsc={false}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
		>
			<div className="d-flex flex-column px-64">
				{isLoading ? (
					<Loader />
				) : (
					<React.Fragment>
						<div
							onClick={() => {
								onCancel();
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div
							className={`d-flex justify-content-center ${classes['text-orange']} ${classes['text-center']} text-h3-325`}
						>
							Confirm Expense Cancellation
						</div>
						<div className="text-white py-16">{getTotalHoldMsg()}</div>
						<div className={`${classes['md-reverse']} row w-100  justify-content-center`}>
							<div className=" d-flex flex-column cl-lg-6  cl-md-12 pt-24">
								<Button
									onClick={() => {
										onCancel();
									}}
								>
									Nevermind
								</Button>
							</div>
							<div className=" d-flex flex-column cl-lg-6 cl-md-12 pt-24">
								<Button onClick={cancelExpense} type={ButtonTypes.TERTIARY}>
									Cancel this Expense
								</Button>
							</div>
						</div>
					</React.Fragment>
				)}
			</div>
		</Modal>
	);
};
