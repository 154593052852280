import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import classes from './AppHeader.module.scss';
// import LogoImg from '../../assets/img/logo.svg';
import UserPool from '../../utils/UserPool';
import { useSelector } from 'react-redux';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { AccountType } from '../../constants/common';

// import { dashboardApi } from '../../services/dashboardService';

import {
	Expense,
	Home,
	Profile,
	MenuBar,
	ChevronClose,
	Doc,
	Faq,
	Message,
	Attachment,
	Logout
} from '../icons';
import { ContactUs } from '../ContactUs';
import { APP_VERSION } from '../../constants/version';
import { Button, ButtonSizes, ButtonTypes } from '../common/Button';
import { isAccountClosed } from '../../utils/accountUtils';

export const AppHeader = () => {
	const [isSideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
	// const [appVersion, setAppVersion] = useState<string>('');
	const navigate = useNavigate();
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [isHsa, setIsHsa] = useState<boolean>(false);

	const summaryData = useSelector((state: any) => state.summaryData);

	const wrapperRef: any = useRef(null);

	useEffect(() => {
		function handleClickOutside(event: any) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setSideMenuOpen(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [wrapperRef]);

	// const getAppVersion = () => {
	// 	dashboardApi
	// 		.getAppVersion()
	// 		.then(response => {
	// 			console.log('ap', appVersion, response.data);
	// 			setAppVersion(response.data);
	// 		})
	// 		.catch();
	// };
	useEffect(() => {
		if (!summaryData.loading) {
			const hsaPresent = summaryData.data?.hsa_summary.find(
				(account: { type: string; account_status: string }) => account.type == AccountType.HSA
			);
			if (hsaPresent) {
				setIsHsa(true);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summaryData]);

	// Clear the local storage and redirect to login page
	const signOutUser = () => {
		try {
			const cognitoUser: any = UserPool.getCurrentUser();
			cognitoUser.signOut();
		} catch (error) {}

		localStorageUtil.clearTokens();
		localStorage.removeItem('card-activation-dismissed');
		localStorage.removeItem('card-travel-dismissed');
		localStorage.removeItem('grace-card-dismissed');
		localStorage.removeItem('runout-card-dismissed');
		Object.keys(localStorage)
			.filter(key => key.startsWith('suspended-card-dismissed'))
			.forEach(key => localStorage.removeItem(key));
		navigate('/login');
	};

	// useEffect(() => {
	// 	getAppVersion();
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return (
		<React.Fragment>
			<ContactUs
				openModal={isContactUsOpen}
				closeModal={() => {
					setIsContactUsOpen(false);
				}}
				eventPage="Header"
			/>
			<div
				className={`${classes['side-bar']} ${isSideMenuOpen ? classes['side-bar-open'] : ''}`}
				ref={wrapperRef}
			>
				<div className="d-flex justify-content-start mb-32">
					<Button
						type={ButtonTypes.TRANSPARENT}
						size={ButtonSizes.DEFAULT}
						otherClasses={classes['close-icon']}
						onClick={() => {
							setSideMenuOpen(!isSideMenuOpen);
						}}
					>
						<ChevronClose title="Close the sidebar" titleId="closethesidebar" color="#FFFFFF" />
					</Button>
				</div>

				{isHsa && (
					<Button
						type={ButtonTypes.TRANSPARENT}
						size={ButtonSizes.DEFAULT}
						onClick={() => {
							navigate('/apps/statements/hsa');
							setSideMenuOpen(false);
						}}
						otherClasses={classes['side-bar-option']}
					>
						<div className="d-flex align-items-center">
							<span className="d-flex align-items-center px-8">
								<Doc color="#FFFFFF" />
							</span>

							<p className={classes['side-bar-option-text']}>Tax Docs & Statements</p>
						</div>
						<div className="d-flex align-items-center">
							<ChevronClose color="#FFFFFF" />
						</div>
					</Button>
				)}

				<Button
					type={ButtonTypes.TRANSPARENT}
					size={ButtonSizes.DEFAULT}
					otherClasses={classes['side-bar-option']}
					onClick={() => {
						window.open('https://www.incomm.com/benefits/spending-account-faq/');
						setSideMenuOpen(false);
					}}
				>
					<div className="d-flex align-items-center">
						<span className="d-flex align-items-center px-8">
							<Faq color="#FFFFFF" />
						</span>

						<p className={classes['side-bar-option-text']}>FAQ</p>
					</div>
					<div className="d-flex align-items-center">
						<ChevronClose color="#FFFFFF" />
					</div>
				</Button>

				<Button
					type={ButtonTypes.TRANSPARENT}
					size={ButtonSizes.DEFAULT}
					otherClasses={classes['side-bar-option']}
					onClick={() => {
						setIsContactUsOpen(true);
						setSideMenuOpen(false);
					}}
				>
					<div className="d-flex align-items-center">
						<span className="d-flex align-items-center px-8">
							<Message color="#FFFFFF" />
						</span>

						<p className={classes['side-bar-option-text']}>Contact Us</p>
					</div>
					<div className="d-flex align-items-center">
						<ChevronClose color="#FFFFFF" />
					</div>
				</Button>

				<Button
					type={ButtonTypes.TRANSPARENT}
					size={ButtonSizes.DEFAULT}
					otherClasses={classes['side-bar-option']}
					onClick={() => {
						window.open('https://incommbenefits.com/legal');
						setSideMenuOpen(false);
					}}
				>
					<div className="d-flex align-items-center">
						<span className="d-flex align-items-center px-8">
							<Attachment color="#FFFFFF" />
						</span>

						<p className={classes['side-bar-option-text']}>Legal Documents</p>
					</div>
					<div className="d-flex align-items-center">
						<ChevronClose color="#FFFFFF" />
					</div>
				</Button>

				<Button
					type={ButtonTypes.TRANSPARENT}
					size={ButtonSizes.DEFAULT}
					otherClasses={classes['side-bar-option']}
					onClick={signOutUser}
				>
					<div className="d-flex align-items-center">
						<span className="d-flex align-items-center px-8">
							<Logout color="#FFFFFF" />
						</span>
						<p className={classes['side-bar-option-text']}>Sign Out</p>
					</div>
					<div className="d-flex align-items-center">
						<ChevronClose color="#FFFFFF" />
					</div>
				</Button>

				<div className="d-flex justify-content-center mt-32">Version {APP_VERSION}</div>
			</div>
			<div className={classes.container}>
				<div className={classes['menu-bar']}>
					<Button
						type={ButtonTypes.TRANSPARENT}
						size={ButtonSizes.DEFAULT}
						otherClasses={classes['side-bar-icon']}
						onClick={() => {
							setSideMenuOpen(true);
						}}
					>
						<MenuBar width={24} color="#fafcfe" title="Open Sidebar" titleId="opensidebar" />
					</Button>
				</div>
				<div className={classes.menu}>
					<NavLink
						to={'/apps/dashboard'}
						className={({ isActive }) => 'custom-nav-link ' + (isActive ? 'active' : '')}
					>
						<Home width={24} aria-hidden />
						<span className="sm-hide d-flex align-items-center">Home</span>
					</NavLink>
					<NavLink
						to={'/apps/expenses/summary'}
						className={({ isActive }) =>
							'custom-nav-link ' +
							(isActive || location.pathname.includes('apps/expense') ? 'active' : '')
						}
					>
						<Expense width={24} aria-hidden />
						{summaryData.data.expense_count && !isAccountClosed(summaryData.data) ? (
							<span className={classes['sup-badge']}>{summaryData.data.expense_count}</span>
						) : (
							''
						)}

						<span className="sm-hide d-flex align-items-center">Expenses</span>
					</NavLink>
					<NavLink
						to={'/apps/profile'}
						className={({ isActive }) => 'custom-nav-link ' + (isActive ? 'active' : '')}
					>
						<Profile width={24} aria-hidden />
						<span className="sm-hide d-flex align-items-center">Profile</span>
					</NavLink>
				</div>
				{/* <div
					className={classes.logo}
					onClick={() => {
						// navigate('/dashboard');
					}}
				>
					<img height={34} src={LogoImg} />
				</div> */}
			</div>
		</React.Fragment>
	);
};
