import React from 'react';
import classes from './Loader.module.scss';
import loadingGif from '../../../assets/img/Loading.gif';
import { createPortal } from 'react-dom';
const loaderEl = document.getElementById('loader');

type LoaderProps = {
	withBackground?: boolean;
	children?: React.ReactNode | string;
	className?: string;
};

// { withBackground = true, children, className }: LoaderProps

export const Loader = ({ withBackground = true, children }: LoaderProps) => {
	return withBackground ? (
		createPortal(
			<div className={classes['loader-box']}>
				<div className={classes['loader-background']}></div>
				<div className={classes['app-loader']} role="status">
					<img src={loadingGif} width={180} alt="Loading" />
					{children}
				</div>
			</div>,
			loaderEl as HTMLElement
		)
	) : (
		<div className={classes['app-loader']}>
			<img src={loadingGif} width={180} />
			{children}
		</div>
	);
};
