/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import classes from './ConfirmAddress.module.scss';
import { Button, ButtonTypes } from '../../common/Button';
import { StyledLink } from '../../common/Link';
import { Incomplete, Profile, Warning } from '../../../components/icons';
import Modal from 'react-modal';
import { ContactUs } from '../../ContactUs';
import { Banner, BannerTypes } from '../../common/Banner';
import { useForm, Controller } from 'react-hook-form';
import { dateUtil } from '../../../utils/dateUtil';
import { contactApi } from '../../../services/profileService';
import { Loader } from '../../../components/common/Loader';
import { Input, InputTypes } from '../../common/Input';
import { FormControl } from '../../common/FormControl';
import Select from 'react-select';
import { getSelectStyle, stateOptions } from '../../../constants/common';
import { expenseApi } from '../../../services/expenseService';
import { getDecimalVal } from '../../../utils/numberUtil';
import { useNavigate } from 'react-router-dom';
import { ControlType } from '../../common/FormControl/FormControl.constants';

type ReimburseData = {
	expense_draft_id: string;
	evaluation_type: string;
	reimburse_to: string;
	reimburse_from: string;
	is_reimburse_now: boolean;
};

type StepProps = {
	nextStep: () => void;
	previousStep: () => void;
	expenseDetails: {
		reimburseData: ReimburseData;
		evaluateData: any;
	};
};

type AddressFormValues = {
	address_line_1: string;
	address_line_2: string;
	city: string;
	state: string;
	zip_code: string | number;
};

export const ConfirmAddress = ({ nextStep, previousStep, expenseDetails }: StepProps) => {
	const [addressModalIsOpen, setAddressModalOpen] = useState<boolean>(false);
	const [isContactUsOpen, setIsContactUsOpen] = useState<boolean>(false);
	const [addressUpdateError, setAddressUpdateError] = useState<string>('');
	const [isLoading, setLoading] = useState(false);
	const [isAddressUpdating, setAddressUpdating] = useState<boolean>(false);
	const [transferModalIsOpen, setTransferModalIsOpen] = useState<boolean>(false);

	const [address, setAddress] = React.useState<AddressFormValues>({
		address_line_1: '',
		address_line_2: '',
		city: '',
		state: '',
		zip_code: ''
	});
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		//register,
		control,
		setValue,
		handleSubmit,
		formState: {},
		reset
	} = useForm<AddressFormValues>({
		mode: 'onBlur',
		defaultValues: address
	});

	const setAddressValues = () => {
		reset();
		setValue('address_line_1', address.address_line_1);
		setValue('address_line_2', address.address_line_2);
		setValue('city', address.city);
		setValue('state', address.state);
		setValue('zip_code', address.zip_code);
	};

	const [userInfo, setUserInfo] = useState({
		name: '',
		address: '',
		email: '',
		phone_number: ''
	});

	const closeAddressModal = () => {
		setAddressUpdateError('');
		setAddressModalOpen(false);
	};

	const getPersonalDetails = () => {
		setLoading(true);
		contactApi
			.contactInformation()
			.then((response: any) => {
				const userData = response.data.data;
				setUserInfo({
					name: `${userData.first_name} ${userData.last_name}`,
					address: `${userData.address_line_1 || ''}, ${userData.address_line_2 || ''}, 
					${userData.city || ''}, ${userData.state || ''} ${userData.zip_code || ''}`,
					email: userData.personal_email.toLowerCase(),
					phone_number: userData.phone_number
				});
				setAddress(userData);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};
	const getStateLabel = (stateCode: string) => {
		return stateOptions.find(state => state.value === stateCode)?.label;
	};

	const haveEnoughBalance = () => {
		const selectedAccount = Object.values(expenseDetails.evaluateData.primary_from).find(
			(value: any) => value.account == expenseDetails.reimburseData.reimburse_from
		);
		return (selectedAccount as { can_reimburse_full_amount: boolean }).can_reimburse_full_amount;
	};
	const onSubmit = handleSubmit((data: AddressFormValues) => {
		setAddressUpdating(true);
		setAddressUpdateError('');
		contactApi
			.updateAddress({ ...data, sent_at: dateUtil.getEpochTime() })
			.then(() => {
				getPersonalDetails();
				closeAddressModal();
				setAddressUpdating(false);
			})
			.catch((error: any) => {
				setAddressUpdating(false);
				setAddressUpdateError(error.response?.data?.error?.message || '');
			});
	});

	const createExpense = () => {
		setLoading(true);
		const postData: any = { ...expenseDetails.reimburseData };
		if (postData.reimburse_from) {
			delete postData?.reimburse_from;
		}

		expenseApi
			.createExpense(postData)
			.then(() => {
				setLoading(false);

				if (expenseDetails.reimburseData.reimburse_from == 'HSA' && !haveEnoughBalance()) {
					navigate('/apps/expenses/summary');
				} else {
					nextStep();
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			width: '100%',
			maxWidth: '565px',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			border: 'none'
			// padding: '56px 24px',
			// backgroundColor: '#001b47',
			// borderColor: '#001b47'
		}
	};

	useEffect(() => {
		setAddressUpdateError('');
		getPersonalDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitButton = () => {
		if (expenseDetails.reimburseData.reimburse_from == 'HSA' && haveEnoughBalance()) {
			return (
				<Button
					onClick={() => {
						setTransferModalIsOpen(true);
					}}
				>
					Reimburse Now
				</Button>
			);
		} else if (expenseDetails.reimburseData.reimburse_from == 'HSA' && !haveEnoughBalance()) {
			return (
				<Button
					onClick={() => {
						createExpense();
					}}
				>
					Save to Reimburse Later
				</Button>
			);
		} else {
			return (
				<Button
					onClick={() => {
						createExpense();
					}}
				>
					Submit Expense
				</Button>
			);
		}
	};
	if (isLoading) {
		return <Loader />;
	}
	return (
		<React.Fragment>
			<Modal
				isOpen={addressModalIsOpen}
				style={customStyles}
				onRequestClose={closeAddressModal}
				contentLabel="Address Modal"
				ariaHideApp={false}
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
			>
				<div className="d-flex flex-column">
					{isAddressUpdating ? (
						<Loader />
					) : (
						<React.Fragment>
							<ContactUs
								openModal={isContactUsOpen}
								closeModal={() => {
									setIsContactUsOpen(false);
								}}
								eventPage="Expense Create - Address Modal"
							/>

							<div className="modal-header">
								<div onClick={closeAddressModal} className="modal-close-btn-container">
									<Incomplete></Incomplete>
								</div>
								<div className={`text-white text-center mt-32 text-h4-325`}>
									Edit your Home Address
								</div>
							</div>
							<div className="modal-body">
								{addressUpdateError && (
									<>
										<br />
										<Banner type={BannerTypes.NEGATIVE} icon={Warning}>
											{addressUpdateError ==
											'Your home address cannot be a PO Box. Please contact us for assistance.' ? (
												<p>
													Your home address cannot be a PO Box. Please{' '}
													<StyledLink
														onClick={() => {
															setIsContactUsOpen(true);
														}}
														//href="mailto: support@getzenda.com"
													>
														contact us
													</StyledLink>{' '}
													for assistance.
												</p>
											) : (
												addressUpdateError
											)}
										</Banner>
									</>
								)}

								<form onSubmit={onSubmit} className={classes.form}>
									<div>
										<Controller
											control={control}
											name="address_line_1"
											rules={{
												required: 'Address is required.'
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<FormControl
														label={() => 'Address Line 1'}
														error={() => error?.message || ''}
														controlType={ControlType.DARK}
													>
														<Input
															onchange={evt => {
																if (evt.target.value?.length <= 40) {
																	onChange(evt);
																}
															}}
															onClear={() => {
																setValue('address_line_1', '');
															}}
															isIncomplete={error ? true : false}
															isClearable={true}
															type={InputTypes.TEXT}
															value={value}
															placeholder={'Address'}
														/>
													</FormControl>
												</>
											)}
										/>
									</div>

									<div>
										<Controller
											control={control}
											name="address_line_2"
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<FormControl
														label={() => 'Address Line 2'}
														error={() => error?.message || ''}
														controlType={ControlType.DARK}
													>
														<Input
															onchange={evt => {
																if (evt.target.value?.length <= 40) {
																	onChange(evt);
																}
															}}
															onClear={() => {
																setValue('address_line_2', '');
															}}
															isIncomplete={error ? true : false}
															isClearable={true}
															type={InputTypes.TEXT}
															value={value}
															placeholder={'Address'}
														/>
													</FormControl>
												</>
											)}
										/>
									</div>

									<div>
										<Controller
											control={control}
											name="city"
											rules={{
												required: 'City is required.'
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<FormControl
														label={() => 'City'}
														error={() => error?.message || ''}
														controlType={ControlType.DARK}
													>
														<Input
															onchange={evt => {
																if (evt.target.value?.length <= 30) {
																	onChange(evt);
																}
															}}
															onClear={() => {
																setValue('city', '');
															}}
															isIncomplete={error ? true : false}
															isClearable={true}
															type={InputTypes.TEXT}
															value={value}
															placeholder={'City'}
														/>
													</FormControl>
												</>
											)}
										/>
									</div>

									<div>
										<Controller
											control={control}
											name="state"
											rules={{
												required: 'State is required.'
											}}
											render={({ field: { value }, fieldState: { error } }) => (
												<>
													<FormControl
														label={() => 'State'}
														error={() => error?.message || ''}
														controlType={ControlType.DARK}
													>
														<Select
															options={stateOptions}
															onChange={selected => setValue('state', selected?.value || '')}
															isSearchable={true}
															placeholder={'State'}
															value={{ value, label: getStateLabel(value) }}
															styles={{
																control: getSelectStyle
															}}
														/>
													</FormControl>
												</>
											)}
										/>
									</div>

									<div>
										<Controller
											control={control}
											name="zip_code"
											rules={{
												required: 'Zipcode required.',
												minLength: { value: 5, message: 'Length must be 5 characters.' },
												maxLength: { value: 5, message: 'Length must be 5 characters.' },
												pattern: {
													value: /^([0-9]){5,}$/,
													message: 'Only numeric characters are allowed.'
												}
											}}
											render={({ field: { onChange, value }, fieldState: { error } }) => (
												<>
													<FormControl
														label={() => 'Zipcode'}
														error={() => error?.message || ''}
														controlType={ControlType.DARK}
													>
														<Input
															onchange={onChange}
															onClear={() => {
																setValue('zip_code', '');
															}}
															placeholder={'Zip code'}
															isIncomplete={error ? true : false}
															isClearable={true}
															type={InputTypes.TEXT}
															value={value.toString()}
															maxLength={5}
														/>
													</FormControl>
												</>
											)}
										/>
									</div>
									<div
										className={`d-flex justify-content-center align-items-center flex-column gap-20`}
									>
										<Button type={ButtonTypes.PRIMARY}>Update</Button>
										<Button type={ButtonTypes.SECONDARY} onClick={closeAddressModal}>
											Cancel
										</Button>
									</div>
								</form>
							</div>
						</React.Fragment>
					)}
				</div>
			</Modal>
			<Modal
				isOpen={transferModalIsOpen}
				style={customStyles}
				onRequestClose={() => {
					setTransferModalIsOpen(false);
				}}
				contentLabel="Transfer Modal"
				shouldCloseOnEsc={false}
				shouldCloseOnOverlayClick={false}
				ariaHideApp={false}
			>
				<div>
					<div className="modal-header">
						<div
							onClick={() => {
								setTransferModalIsOpen(false);
							}}
							className="modal-close-btn-container"
						>
							<Incomplete></Incomplete>
						</div>
						<div className={`text-h3-325 text-white text-center mt-32 `}>Confirm Transfer</div>
					</div>
					<div className="modal-body d-flex flex-column align-items-center">
						<p className={`px-64 py-16 text-center text-base-325 text-primary`}>
							A check for ${getDecimalVal(expenseDetails.evaluateData.amount_to_reimburse)} will be
							written and sent to your mailing address. Do you wish to proceed with your
							reimbursement?
						</p>
						<div className={`d-flex flex-row flex mt-16 gap-20`}>
							<Button
								type={ButtonTypes.SECONDARY}
								onClick={() => {
									setTransferModalIsOpen(false);
								}}
							>
								Close
							</Button>
							<Button
								type={ButtonTypes.PRIMARY}
								onClick={() => {
									setTransferModalIsOpen(false);
									createExpense();
								}}
							>
								Yes
							</Button>
						</div>
					</div>
				</div>
			</Modal>
			<div className="d-flex flex-column mt-64">
				<p className={'text-h5-325 text-primary'}>Confirm Mailing Address</p>
				<p className="text-base-325 py-12 text-primary">
					We will mail the check to the address connected to your account:
				</p>
				<p className="text-base-700 text-primary">{userInfo?.name}</p>
				<p className="text-base-325 text-primary">{address.address_line_1}</p>
				{address.address_line_2 && (
					<p className="text-base-325 text-primary">{address.address_line_2}</p>
				)}
				<p className="text-base-325 text-primary">
					{address.city}, {address.state} {address.zip_code}
				</p>
				<div className="py-32">
					<hr />
					<p className="text-base-325 mt-16 mb-12 text-primary d-flex justify-content-center">
						Not the correct Address?
					</p>

					<div className="d-flex justify-content-center align-items-center py-12 ">
						<Profile color={'#296DB2'} />
						<span className="px-4">
							<StyledLink
								onClick={() => {
									setAddressValues();
									setAddressModalOpen(true);
								}}
							>
								<span className="text-base-350">Update Your Account Information</span>
							</StyledLink>
						</span>
					</div>

					<hr />
					<br />
				</div>

				{submitButton()}
				<br />
				<Button type={ButtonTypes.SECONDARY} onClick={previousStep}>
					Back
				</Button>
			</div>
		</React.Fragment>
	);
};
