// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgPayback = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 25 24"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#111827`}
			d="M12.5 20.75a7.26 7.26 0 0 1-7.25-7.25.75.75 0 1 1 1.5 0 5.75 5.75 0 1 0 5.75-5.75H10a.75.75 0 0 1 0-1.5h2.5a7.25 7.25 0 1 1 0 14.5"
		/>
		<path
			fill={props.color || `#111827`}
			d="M12.5 10.75a.74.74 0 0 1-.53-.22l-3-3a.75.75 0 0 1 0-1.06l3-3a.75.75 0 1 1 1.06 1.06L10.56 7l2.47 2.47a.75.75 0 0 1-.53 1.28"
		/>
	</svg>
);
export default SvgPayback;
