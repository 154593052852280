import React from 'react';
import classes from './Header.module.scss';
import LogoImg from '../../assets/img/INCLogo.svg';
import { StyledLink } from '../common/Link';

export const Header = () => {
	// Hide the action menu for login and sign up routes

	// Clear the local storage and redirect to login page

	return (
		<nav className={classes.header}>
			<StyledLink href={'/login'} isOpenInNewTab={false}>
				<img height={56} src={LogoImg} />
			</StyledLink>
		</nav>
	);
};
