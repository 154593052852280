export enum InputTypes {
	TEXT = 'TEXT',
	PASSWORD = 'PASSWORD',
	EMAIL = 'EMAIL'
}

export enum InputSizes {
	SM = 'SMALL',
	MD = 'MEDIUM',
	LG = 'LARGE',
	XL = 'EXTRA_LARGE'
}
