// Auto-generated file created by svgr-cli source svg-template.js
// Run yarn icons:create to update
// Do not edit
import * as React from 'react';
import type { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
}
const SvgCard2 = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || 24}
		height={props.height || 24}
		fill="none"
		viewBox="0 0 32 32"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fill={props.color || `#25282B`}
			d="M23.527 29.44c-.521 0-1.046-.094-1.559-.289l-19.116-7.25a4.39 4.39 0 0 1-2.46-2.317 4.381 4.381 0 0 1-.103-3.375L4.347 5.516a4.42 4.42 0 0 1 5.691-2.562l19.113 7.25a4.42 4.42 0 0 1 2.562 5.692l-4.057 10.693a4.425 4.425 0 0 1-4.134 2.85h.005Zm-.962-1.873a2.73 2.73 0 0 0 3.511-1.58l4.057-10.692a2.727 2.727 0 0 0-1.58-3.511L9.442 4.538a2.725 2.725 0 0 0-3.51 1.58L1.872 16.81a2.684 2.684 0 0 0 .064 2.079 2.698 2.698 0 0 0 1.516 1.427l19.112 7.25Z"
		/>
		<path
			fill={props.color || `#25282B`}
			d="M30.286 18.123a.822.822 0 0 1-.301-.055L8.425 9.891a.849.849 0 0 1-.491-1.093.849.849 0 0 1 1.092-.491l21.56 8.178a.849.849 0 0 1 .492 1.092.844.844 0 0 1-.792.546ZM9.53 19.665a.822.822 0 0 1-.3-.055l-3.926-1.487a.849.849 0 0 1-.492-1.092.849.849 0 0 1 1.093-.491l3.926 1.486a.85.85 0 0 1 .491 1.093.844.844 0 0 1-.792.546Z"
		/>
	</svg>
);
export default SvgCard2;
