import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { decrypt } from './crypto';

interface IPoolData {
	UserPoolId: string;
	ClientId: string;
}
const cognitoPoolId: string = decrypt(process.env.REACT_APP_COGNITO_USER_POOL_ID || '');
const cognitoClientId: string = decrypt(process.env.REACT_APP_COGNITO_CLIENT_ID || '');

const poolData: IPoolData = {
	UserPoolId: cognitoPoolId,
	ClientId: cognitoClientId
};

export default new CognitoUserPool(poolData);
